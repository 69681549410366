import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import markdownToHtml from "helpers/utils/markdownLoader";
import { Post } from "types";
import * as styles from "./Featured.module.scss";

const Featured: React.FC<Post["attributes"]> = ({
  slug,
  title,
  description,
  featuredImage,
}) => {
  const {
    attributes: { localFile, url: imageUrl, ext: imageExt },
  } = featuredImage.data;
  const image = getImage(localFile);

  return (
    <article
      data-type="BlogPost-Featured"
      className={styles.Featured_container}
    >
      <div className={styles.Featured_content}>
        <div>
          <p className={styles.Featured_type}>FEATURED ARTICLE</p>
          <h3 className={styles.Featured_title}>{title}</h3>
          <div
            className={styles.Featured_description}
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(description),
            }}
          ></div>
        </div>
        <Link className={styles.Featured_link} to={slug}>
          Read more
        </Link>
      </div>
      {imageExt === ".gif" ? (
        <div className={styles.Featured_image}>
          <img src={imageUrl} alt={title} />
        </div>
      ) : (
        <GatsbyImage
          className={styles.Featured_image}
          image={image!}
          alt={title}
        />
      )}
    </article>
  );
};

export default Featured;

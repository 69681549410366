import React from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
import { StaticImage } from "gatsby-plugin-image";
import { Button, ButtonVariant } from "brass-ui-kit";

import * as styles from "./Hero.module.scss";
import { newsletterValidationSchema } from "./validation";
import BrassService from "services/brassService";

interface NewsLetterProps {
  email: string;
}

const Hero: React.FC = () => {
  const initialValues: NewsLetterProps = {
    email: "",
  };

  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleSubmission = async (value: NewsLetterProps) => {
    setIsLoading(true);
    setError(null);

    try {
      await BrassService.put("/marketing/subscribers", value);

      setIsSubmitted(true);
    } catch (err: any) {
      setError(
        err.response ? err.response.data.error.description : err.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <header className={styles.Hero_header}>
          <h2 className={styles.Hero_header_title}>
            Highlights and top stories from Brass
          </h2>
          <p>Be the first to get updates and announcements from Brass!</p>
        </header>

        {!isSubmitted && (
          <Formik
            onSubmit={handleSubmission}
            initialValues={initialValues}
            validationSchema={newsletterValidationSchema}
          >
            {({
              errors,
              values,
              isValid,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={cx(styles.Hero_form, {
                  [styles.Hero_form_submitted]: isSubmitted,
                })}
              >
                <input
                  type="email"
                  name="email"
                  autoFocus={true}
                  onBlur={handleBlur}
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  aria-label="Email Address"
                  placeholder="Enter your email address"
                />
                <Button
                  type="submit"
                  loading={isLoading}
                  variant={ButtonVariant.Primary}
                  disabled={!isValid || isLoading}
                >
                  Get Notified
                </Button>
              </form>
            )}
          </Formik>
        )}
        {isSubmitted && (
          <p className={styles.Hero_form_success}>
            Thank you, you'll be among the first to get our updates and
            announcements.
          </p>
        )}
        {error && <p className={styles.Hero_form_error}>{error}</p>}
      </div>
      <div className={styles.Hero_backgroundImage}>
        <StaticImage
          as="span"
          alt="hero"
          loading="eager"
          src="../../../assets/media/images/blog/hero.png"
        />
      </div>
    </section>
  );
};

export default Hero;

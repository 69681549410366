import React from "react";
import { Link } from "gatsby";
import markdownToHtml from "helpers/utils/markdownLoader";
import { Post } from "types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./Card.module.scss";

const Card: React.FC<Post["attributes"]> = ({
  slug,
  date,
  title,
  category,
  description,
  featuredImage: {
    data: {
      attributes: { localFile, url: imageUrl, ext: imageExt },
    },
  },
}) => {
  const image = getImage(localFile);

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = new Date(date).toLocaleDateString(
    "en-US",
    dateOptions as any
  );

  return (
    <article data-type="BlogPost">
      <Link className={styles.Card_container} to={slug}>
        {imageExt === ".gif" ? (
          <div className={styles.Card_image}>
            <img src={imageUrl} alt={title} />
          </div>
        ) : (
          <GatsbyImage
            className={styles.Card_image}
            image={image!}
            alt={title}
          />
        )}
        <div className={styles.Card_content}>
          <div>
            <h3 className={styles.Card_title}>{title}</h3>
            <div
              className={styles.Card_description}
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(description),
              }}
            ></div>
          </div>
          <div className={styles.Card_meta}>
            <div className={styles.Card_meta_left}>
              <p className={styles.Card_meta_team}>
                {category.data.attributes.name}
              </p>
              <p className={styles.Card_meta_date}>{formattedDate}</p>
            </div>
            <Link className={styles.Card_meta_link} to={slug}>
              Read more
            </Link>
          </div>
        </div>
      </Link>{" "}
    </article>
  );
};

export default Card;

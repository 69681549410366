import { DefaultValuesType } from "context/Layout";
import { AppDownloadType, AuthActionType } from "types";

export const DEFAULT_DOWNLOAD_ACTION_DATA = {
  label: "",
  content: [
    {
      text: "",
      action: () => null,
    },
    {
      text: "",
      action: () => null,
    },
  ],
};

export const downloadActionTypeData = (
  action: (x: Partial<DefaultValuesType>) => void,
  handleBussinessAppRedirect: () => void,
  handleStartAppRedirect: () => void
) => ({
  [AuthActionType.Login]: {
    label: "Log into your account",
    content: [
      {
        text: "Log in as a registered business",
        action: handleBussinessAppRedirect,
      },
      {
        text: "Log in as an unregistered business",
        action: handleStartAppRedirect,
      },
    ],
  },
  [AuthActionType.SignUp]: {
    label: "Open a business account",
    content: [
      {
        text: "I have a registered business",
        action: () =>
          action({
            openAppDownloadModal: true,
            appDownloadModalType: AppDownloadType.BusinessApp,
          }),
      },
      {
        text: "I don’t have a registered business",
        action: () =>
          action({
            openAppDownloadModal: true,
            appDownloadModalType: AppDownloadType.StarterApp,
          }),
      },
    ],
  },
});

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./Hero.module.scss";
import useWindowSize from "helpers/hooks/useWindowResize";

interface HeroProps {
  title: string;
  subtitle?: string;
  backgroundImage: any;
  children?: React.ReactNode;
  mobileBackgroundImage?: any;
  useOptimizedBackground?: boolean;
}

const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  children,
  backgroundImage,
  mobileBackgroundImage,
  useOptimizedBackground,
}) => {
  const { width } = useWindowSize();
  let image = getImage(backgroundImage);

  if (mobileBackgroundImage && width < 768) {
    image = getImage(mobileBackgroundImage);
  }

  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <header className={styles.Hero_header}>
          <h1 className={styles.Hero_header_text}>{title}</h1>
          <p className={styles.Hero_header_body}>{subtitle}</p>
          {children}
        </header>
        {backgroundImage && (
          <div className={styles.Hero_backgroundImage}>
            {image && useOptimizedBackground && (
              <GatsbyImage image={image} alt="hero" as="span" />
            )}
            {!useOptimizedBackground && (
              <img src={backgroundImage} alt="hero" />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

Hero.defaultProps = {
  title: "",
  subtitle: "",
  backgroundImage: "",
  mobileBackgroundImage: "",
  useOptimizedBackground: true,
};

export default Hero;

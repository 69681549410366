import React from "react";
import cx from "classnames";

import { RelatedPostCard } from "components/design-system";

import { RelatedPostCardProps } from "../RelatedPostCard/RelatedPostCard";
import * as styles from "./Related.module.scss";

interface RelatedProps {
  title?: string;
  overflow?: boolean;
  items: RelatedPostCardProps[];
  align?: "left" | "center" | "right";
  className?: string;
}

const Related: React.FC<RelatedProps> = ({
  title,
  items,
  align,
  overflow,
  className,
}) => {
  return items.length > 0 ? (
    <section className={cx(styles.Related, className)}>
      <h2 className={styles.Related_title}>{title}</h2>
      <div className={styles.Related_posts}>
        {items.map((item: RelatedPostCardProps, index: number) => (
          <RelatedPostCard
            {...item}
            key={index}
            align={align}
            overflow={overflow}
          />
        ))}
      </div>
    </section>
  ) : null;
};

Related.defaultProps = {
  align: "left",
  overflow: true,
};

export default Related;

import React from "react";
import { ToastContainer } from "brass-ui-kit";
import {
  AppDownloadModal,
  AuthActionModal,
  Footer,
  Navbar,
} from "../../design-system";
import { LayoutContext } from "../../../context";
import { DefaultMethodsType, DEFAULT_VALUES } from "../../../context/Layout";

import { Hero } from "components/blog";

import * as styles from "./PageLayout.module.scss";
import "brass-ui-kit/dist/styles/brass-light.css";

interface PageLayoutProps {
  pageContext: {
    layout: "default" | "blog";
  };
  location: Location;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  pageContext,
  location,
}) => {
  const [state, setState] = React.useState(DEFAULT_VALUES);
  const isBlog = pageContext.layout === "blog";

  const handleStateUpdate: DefaultMethodsType["updateContext"] = (newState) =>
    setState((state) => ({ ...state, ...newState }));

  return (
    <LayoutContext.Provider
      value={{ ...state, updateContext: handleStateUpdate }}
    >
      <main className={styles.PageLayout}>
        <Navbar location={location} />
        <div className={styles.PageLayout_content}>
          {isBlog && <Hero />}
          {children}
        </div>
        <AppDownloadModal />
        <AuthActionModal />
        <Footer location={location} />
      </main>
      <ToastContainer />
    </LayoutContext.Provider>
  );
};

PageLayout.defaultProps = {
  pageContext: {
    layout: "default",
  },
};
export default PageLayout;

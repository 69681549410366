import { createContext } from "react";
import { window } from "browser-monads";
import { AppDownloadType, AuthActionType } from "types";

export type DefaultValuesType = {
  isFooterButtonSticky: boolean;
  openAuthActionModal: boolean;
  authActionModalType: AuthActionType | null;
  openAppDownloadModal: boolean;
  appDownloadModalType: AppDownloadType | null;
};

export type DefaultMethodsType = {
  updateContext: (x: Partial<DefaultValuesType>) => void;
};

export const DEFAULT_VALUES: DefaultValuesType = {
  isFooterButtonSticky: window.innerWidth < 768,
  openAuthActionModal: false,
  authActionModalType: null,
  openAppDownloadModal: false,
  appDownloadModalType: null,
};

export const DEFAULT_METHODS: DefaultMethodsType = {
  updateContext: () => null,
};

const LayoutContext = createContext({
  ...DEFAULT_VALUES,
  ...DEFAULT_METHODS,
});

LayoutContext.displayName = "Layout";

export default LayoutContext;

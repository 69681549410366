import React from "react";
import cx from "classnames";
import { Link } from "gatsby";

import * as styles from "./HighlightCard.module.scss";

interface HighlightCardType {
  link?: string;
  title?: string;
  image?: string;
  linkText?: string;
  onClick?: () => void;
  description?: string;
}

interface HighlightCardProps {
  border?: boolean;
  items: HighlightCardType[];
  size: "large" | "medium" | "small";
}

const HighlightCard: React.FC<HighlightCardProps> = ({
  items,
  size,
  border,
}) => {
  return (
    <div className={styles.HighlightCard_container}>
      <div className={styles.HighlightCard_list}>
        {items.map(
          ({ image, title, description, link, linkText, onClick }, index) => {
            const isInternal = /^\/(?!\/)/.test(link);

            return (
              <div
                key={index}
                className={cx(styles.HighlightCard_list_item, {
                  [styles.HighlightCard_list_item_border]: border === true,
                })}
              >
                <div
                  className={cx(styles.HighlightCard_list_item_image, {
                    [styles.HighlightCard_list_item_image_large]:
                      size === "large",
                  })}
                >
                  <img src={image} alt="" />
                </div>
                <div className={styles.HighlightCard_list_item_body}>
                  <div>
                    <h4 className={styles.HighlightCard_list_item_title}>
                      {title}
                    </h4>
                    <p
                      className={styles.HighlightCard_list_item_desc}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></p>
                  </div>
                  {link && (
                    <div>
                      {isInternal ? (
                        <Link
                          to={link}
                          className={styles.HighlightCard_list_item_link}
                        >
                          {linkText}
                        </Link>
                      ) : (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.HighlightCard_list_item_link}
                        >
                          {linkText}
                        </a>
                      )}
                    </div>
                  )}
                  {onClick && (
                    <div
                      onClick={onClick}
                      className={styles.HighlightCard_list_item_link}
                    >
                      {linkText}
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

HighlightCard.defaultProps = {
  size: "large",
  border: false,
};

export default HighlightCard;

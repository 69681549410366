import React from "react";
import { MDXProvider } from "@mdx-js/react";
import AppAnalytics from "./src/helpers/analytics";
import {
  mdxComponent,
  signupTrackingAndAnalytics,
  handleLoader,
  handleAdSearchParams,
} from "./gatsby-utils/gatsby-browser-utils";

import "brass-ui-kit/dist/styles/brass-light.css";
import "./src/styles/main.scss";

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={mdxComponent}>{element}</MDXProvider>
);

export const onClientEntry = () => {
  AppAnalytics.initialize();
  handleAdSearchParams();
};

export const onInitialClientRender = () => {
  handleLoader(false);
};

export const onRouteUpdateDelayed = () => {
  handleLoader(true);
};

export const onRouteUpdate = () => {
  signupTrackingAndAnalytics();
  handleLoader(false);
};

// extracted by mini-css-extract-plugin
export var CustomVideoPlayer = "CustomVideoPlayer-module--CustomVideoPlayer--ofnIU";
export var CustomVideoPlayer_closeIcon = "CustomVideoPlayer-module--CustomVideoPlayer_closeIcon--gqA76";
export var CustomVideoPlayer_embed = "CustomVideoPlayer-module--CustomVideoPlayer_embed--c0yDT";
export var CustomVideoPlayer_embed_wrapper = "CustomVideoPlayer-module--CustomVideoPlayer_embed_wrapper--2K+mn";
export var container = "CustomVideoPlayer-module--container--m1K6j";
export var gatsbyImageWrapperConstrained = "CustomVideoPlayer-module--gatsby-image-wrapper-constrained--54c3l";
export var gridSpan1010 = "CustomVideoPlayer-module--grid-span-10-10--ky508";
export var gridSpan1011 = "CustomVideoPlayer-module--grid-span-10-11--NP-Ms";
export var gridSpan102 = "CustomVideoPlayer-module--grid-span-10-2--Uya2Y";
export var gridSpan103 = "CustomVideoPlayer-module--grid-span-10-3--4npSw";
export var gridSpan104 = "CustomVideoPlayer-module--grid-span-10-4--bTmjv";
export var gridSpan105 = "CustomVideoPlayer-module--grid-span-10-5--JjiqJ";
export var gridSpan106 = "CustomVideoPlayer-module--grid-span-10-6--LRd2P";
export var gridSpan107 = "CustomVideoPlayer-module--grid-span-10-7--PhBDl";
export var gridSpan108 = "CustomVideoPlayer-module--grid-span-10-8--xkjPQ";
export var gridSpan109 = "CustomVideoPlayer-module--grid-span-10-9--rRk32";
export var gridSpan110 = "CustomVideoPlayer-module--grid-span-1-10--b5cwB";
export var gridSpan111 = "CustomVideoPlayer-module--grid-span-1-11--0INDl";
export var gridSpan1110 = "CustomVideoPlayer-module--grid-span-11-10--87BNF";
export var gridSpan1111 = "CustomVideoPlayer-module--grid-span-11-11--Ojxb+";
export var gridSpan112 = "CustomVideoPlayer-module--grid-span-11-2--Jvt3e";
export var gridSpan113 = "CustomVideoPlayer-module--grid-span-11-3--mijao";
export var gridSpan114 = "CustomVideoPlayer-module--grid-span-11-4--5ZLYF";
export var gridSpan115 = "CustomVideoPlayer-module--grid-span-11-5--2zDCa";
export var gridSpan116 = "CustomVideoPlayer-module--grid-span-11-6--6bL2V";
export var gridSpan117 = "CustomVideoPlayer-module--grid-span-11-7--KFW6k";
export var gridSpan118 = "CustomVideoPlayer-module--grid-span-11-8--5RAhz";
export var gridSpan119 = "CustomVideoPlayer-module--grid-span-11-9--sNlN4";
export var gridSpan12 = "CustomVideoPlayer-module--grid-span-1-2--xGNJg";
export var gridSpan1210 = "CustomVideoPlayer-module--grid-span-12-10--zLls-";
export var gridSpan1211 = "CustomVideoPlayer-module--grid-span-12-11--vL7C9";
export var gridSpan122 = "CustomVideoPlayer-module--grid-span-12-2--5GXOY";
export var gridSpan123 = "CustomVideoPlayer-module--grid-span-12-3--xoggu";
export var gridSpan124 = "CustomVideoPlayer-module--grid-span-12-4--XgeHu";
export var gridSpan125 = "CustomVideoPlayer-module--grid-span-12-5--8D6Qq";
export var gridSpan126 = "CustomVideoPlayer-module--grid-span-12-6--csDW4";
export var gridSpan127 = "CustomVideoPlayer-module--grid-span-12-7--eML4G";
export var gridSpan128 = "CustomVideoPlayer-module--grid-span-12-8--8POwq";
export var gridSpan129 = "CustomVideoPlayer-module--grid-span-12-9--bFnMs";
export var gridSpan13 = "CustomVideoPlayer-module--grid-span-1-3--tcaSz";
export var gridSpan14 = "CustomVideoPlayer-module--grid-span-1-4--EDn0s";
export var gridSpan15 = "CustomVideoPlayer-module--grid-span-1-5--3gHlf";
export var gridSpan16 = "CustomVideoPlayer-module--grid-span-1-6--Xm+RF";
export var gridSpan17 = "CustomVideoPlayer-module--grid-span-1-7--gn-gI";
export var gridSpan18 = "CustomVideoPlayer-module--grid-span-1-8--MiUTB";
export var gridSpan19 = "CustomVideoPlayer-module--grid-span-1-9--i9u2M";
export var gridSpan210 = "CustomVideoPlayer-module--grid-span-2-10---NRgz";
export var gridSpan211 = "CustomVideoPlayer-module--grid-span-2-11--QwPnd";
export var gridSpan22 = "CustomVideoPlayer-module--grid-span-2-2--w+wAR";
export var gridSpan23 = "CustomVideoPlayer-module--grid-span-2-3--CdUR-";
export var gridSpan24 = "CustomVideoPlayer-module--grid-span-2-4--owLF1";
export var gridSpan25 = "CustomVideoPlayer-module--grid-span-2-5--SmyJB";
export var gridSpan26 = "CustomVideoPlayer-module--grid-span-2-6--i-FV3";
export var gridSpan27 = "CustomVideoPlayer-module--grid-span-2-7--DXZUG";
export var gridSpan28 = "CustomVideoPlayer-module--grid-span-2-8--kKyou";
export var gridSpan29 = "CustomVideoPlayer-module--grid-span-2-9--4Ms-Q";
export var gridSpan310 = "CustomVideoPlayer-module--grid-span-3-10--6iLQM";
export var gridSpan311 = "CustomVideoPlayer-module--grid-span-3-11--xUwd-";
export var gridSpan32 = "CustomVideoPlayer-module--grid-span-3-2--MpaYT";
export var gridSpan33 = "CustomVideoPlayer-module--grid-span-3-3--qzgPi";
export var gridSpan34 = "CustomVideoPlayer-module--grid-span-3-4--h1Nqq";
export var gridSpan35 = "CustomVideoPlayer-module--grid-span-3-5--UKEzH";
export var gridSpan36 = "CustomVideoPlayer-module--grid-span-3-6--nGTdZ";
export var gridSpan37 = "CustomVideoPlayer-module--grid-span-3-7--pkN6f";
export var gridSpan38 = "CustomVideoPlayer-module--grid-span-3-8--MAAp0";
export var gridSpan39 = "CustomVideoPlayer-module--grid-span-3-9--H40V-";
export var gridSpan410 = "CustomVideoPlayer-module--grid-span-4-10--k5U3z";
export var gridSpan411 = "CustomVideoPlayer-module--grid-span-4-11--KRQ9V";
export var gridSpan42 = "CustomVideoPlayer-module--grid-span-4-2--zTeux";
export var gridSpan43 = "CustomVideoPlayer-module--grid-span-4-3--7ofKQ";
export var gridSpan44 = "CustomVideoPlayer-module--grid-span-4-4--Q-cpz";
export var gridSpan45 = "CustomVideoPlayer-module--grid-span-4-5--B5doT";
export var gridSpan46 = "CustomVideoPlayer-module--grid-span-4-6--uu7gb";
export var gridSpan47 = "CustomVideoPlayer-module--grid-span-4-7--B0R7z";
export var gridSpan48 = "CustomVideoPlayer-module--grid-span-4-8--ViPHe";
export var gridSpan49 = "CustomVideoPlayer-module--grid-span-4-9--kX+Ui";
export var gridSpan510 = "CustomVideoPlayer-module--grid-span-5-10--JaogI";
export var gridSpan511 = "CustomVideoPlayer-module--grid-span-5-11--zsV3V";
export var gridSpan52 = "CustomVideoPlayer-module--grid-span-5-2--Sj-u6";
export var gridSpan53 = "CustomVideoPlayer-module--grid-span-5-3--04lP7";
export var gridSpan54 = "CustomVideoPlayer-module--grid-span-5-4--PL3iq";
export var gridSpan55 = "CustomVideoPlayer-module--grid-span-5-5--PXWka";
export var gridSpan56 = "CustomVideoPlayer-module--grid-span-5-6--Pdb9q";
export var gridSpan57 = "CustomVideoPlayer-module--grid-span-5-7--7ItWS";
export var gridSpan58 = "CustomVideoPlayer-module--grid-span-5-8--IjQBi";
export var gridSpan59 = "CustomVideoPlayer-module--grid-span-5-9--sdt1Y";
export var gridSpan610 = "CustomVideoPlayer-module--grid-span-6-10--fUebk";
export var gridSpan611 = "CustomVideoPlayer-module--grid-span-6-11--Hm9ZB";
export var gridSpan62 = "CustomVideoPlayer-module--grid-span-6-2--GTtUR";
export var gridSpan63 = "CustomVideoPlayer-module--grid-span-6-3--qD6wI";
export var gridSpan64 = "CustomVideoPlayer-module--grid-span-6-4--HfEoI";
export var gridSpan65 = "CustomVideoPlayer-module--grid-span-6-5--iQf21";
export var gridSpan66 = "CustomVideoPlayer-module--grid-span-6-6--UqjiX";
export var gridSpan67 = "CustomVideoPlayer-module--grid-span-6-7--q+KwQ";
export var gridSpan68 = "CustomVideoPlayer-module--grid-span-6-8--acI8o";
export var gridSpan69 = "CustomVideoPlayer-module--grid-span-6-9--YC73F";
export var gridSpan710 = "CustomVideoPlayer-module--grid-span-7-10--hiRvx";
export var gridSpan711 = "CustomVideoPlayer-module--grid-span-7-11--QIOdr";
export var gridSpan72 = "CustomVideoPlayer-module--grid-span-7-2--qEJad";
export var gridSpan73 = "CustomVideoPlayer-module--grid-span-7-3--Q0kGQ";
export var gridSpan74 = "CustomVideoPlayer-module--grid-span-7-4---xj1Y";
export var gridSpan75 = "CustomVideoPlayer-module--grid-span-7-5--zPa4-";
export var gridSpan76 = "CustomVideoPlayer-module--grid-span-7-6--SKfIz";
export var gridSpan77 = "CustomVideoPlayer-module--grid-span-7-7--H5Tr2";
export var gridSpan78 = "CustomVideoPlayer-module--grid-span-7-8--LYlvf";
export var gridSpan79 = "CustomVideoPlayer-module--grid-span-7-9--MjkAY";
export var gridSpan810 = "CustomVideoPlayer-module--grid-span-8-10--HVmTV";
export var gridSpan811 = "CustomVideoPlayer-module--grid-span-8-11--+ZfZs";
export var gridSpan82 = "CustomVideoPlayer-module--grid-span-8-2--8v7T+";
export var gridSpan83 = "CustomVideoPlayer-module--grid-span-8-3--+3bgz";
export var gridSpan84 = "CustomVideoPlayer-module--grid-span-8-4--Eqelx";
export var gridSpan85 = "CustomVideoPlayer-module--grid-span-8-5--1gzsw";
export var gridSpan86 = "CustomVideoPlayer-module--grid-span-8-6--5hubQ";
export var gridSpan87 = "CustomVideoPlayer-module--grid-span-8-7--3gXkr";
export var gridSpan88 = "CustomVideoPlayer-module--grid-span-8-8--2q7J7";
export var gridSpan89 = "CustomVideoPlayer-module--grid-span-8-9--Kx3Bd";
export var gridSpan910 = "CustomVideoPlayer-module--grid-span-9-10--7+z6i";
export var gridSpan911 = "CustomVideoPlayer-module--grid-span-9-11---FK1k";
export var gridSpan92 = "CustomVideoPlayer-module--grid-span-9-2--e-MJG";
export var gridSpan93 = "CustomVideoPlayer-module--grid-span-9-3--5clze";
export var gridSpan94 = "CustomVideoPlayer-module--grid-span-9-4--VLsep";
export var gridSpan95 = "CustomVideoPlayer-module--grid-span-9-5--TpbuV";
export var gridSpan96 = "CustomVideoPlayer-module--grid-span-9-6--Ry4Km";
export var gridSpan97 = "CustomVideoPlayer-module--grid-span-9-7--EhrLV";
export var gridSpan98 = "CustomVideoPlayer-module--grid-span-9-8--naSbe";
export var gridSpan99 = "CustomVideoPlayer-module--grid-span-9-9--oVXI+";
export var textBreak = "CustomVideoPlayer-module--text-break--gIZ4C";
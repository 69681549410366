import React from "react";
import { navigate } from "gatsby-link";
import { Select, SelectVariant } from "brass-ui-kit";
import { Link, PageProps } from "gatsby";
import { Card, FeaturedPost } from "components/blog";

import * as styles from "./BlogSection.module.scss";
import { Category, Post } from "types";

interface BlogSectionProps extends Partial<PageProps> {
  sections: Category[];
  posts: Post[];
}

const BlogSection: React.FC<BlogSectionProps> = ({
  sections,
  posts,
  location,
}) => {
  let formattedSections = sections.map(
    ({ attributes: { name }, pagePath: value }) => ({ name, value })
  );

  const formattedPosts = posts.filter(
    ({ attributes: { featuredPost } }) => featuredPost !== true
  );

  const featuredPost = posts.filter(
    ({ attributes: { featuredPost } }) => featuredPost === true
  )[0];

  return (
    <section className={styles.BlogSection}>
      <div className={styles.BlogSection_select_container}>
        <Select
          labelField="name"
          valueField="value"
          value={location?.pathname!}
          placeholder="Select a category"
          variant={SelectVariant.Default}
          onChange={(item) => navigate(`${item.value}`)}
          options={[{ name: "All", value: "/blog" }, ...formattedSections]}
        />
      </div>
      <div className={styles.BlogSection_nav_laptop_container}>
        <div className={styles.BlogSection_nav_laptop}>
          {[{ name: "All", value: "/blog" }, ...formattedSections].map(
            ({ name, value }, index) => (
              <Link
                key={index}
                to={`${value}`}
                className={styles.BlogSection_nav_item}
                activeClassName={styles.BlogSection_nav_item_selected}
              >
                {name}
              </Link>
            )
          )}
        </div>
      </div>
      <section className={styles.BlogSection_posts}>
        {posts.length > 0 ? (
          <>
            {formattedPosts?.slice(0, 2).map(({ attributes }) => (
              <Card
                {...(attributes as any)}
                key={attributes.slug}
                slug={`/blog/${attributes.category.data.attributes.slug}/${attributes.slug}`}
              />
            ))}

            {featuredPost && (
              <FeaturedPost
                {...(featuredPost.attributes as any)}
                slug={`/blog/${featuredPost.attributes.category.data.attributes.slug}/${featuredPost.attributes.slug}`}
              />
            )}

            {formattedPosts?.slice(2).map(({ attributes }) => (
              <Card
                {...(attributes as any)}
                key={attributes.slug}
                slug={`/blog/${attributes.category.data.attributes.slug}/${attributes.slug}`}
              />
            ))}
          </>
        ) : (
          <div className={styles.BlogSection_empty}>
            <h4 className={styles.BlogSection_empty_title}>
              Nothing to see here
            </h4>
            <p className={styles.BlogSection_empty_text}>
              There are no posts available under this category yet.
            </p>
          </div>
        )}
      </section>
    </section>
  );
};

export default BlogSection;

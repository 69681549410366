import * as styles from "./CustomVideoPlayer.module.scss";
import { window } from "browser-monads";

import { IconClose } from "assets/media/svgs";
import React from "react";
import { VIDEO_SOURCES } from "data/config";

interface CustomVideoPlayerProps {
  videoID: string;
  handleClose: () => void;
  show: boolean;
  source: string;
}

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
  handleClose,
  videoID,
  source,
  show,
}) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  const handleCloseModal = () => {
    handleClose();
  };

  React.useEffect(() => {
    const modal = modalRef.current;
    const handleClick = (e: MouseEvent) => {
      console.log(e);
      if (modal && modal.contains(e.target as any)) handleCloseModal();
    };
    window?.addEventListener("click", handleClick);
    return () => {
      window?.removeEventListener("click", handleClick);
    };
  }, [show]);

  return show ? (
    <section className={styles.CustomVideoPlayer}>
      <div
        className={styles.CustomVideoPlayer_embed_wrapper}
        ref={modalRef}
        style={{ height: "100%", width: "100%" }}
      >
        <IconClose
          className={styles.CustomVideoPlayer_closeIcon}
          onClick={handleCloseModal}
        />
        <div
          className={styles.CustomVideoPlayer_embed}
          dangerouslySetInnerHTML={{
            __html: `<iframe src="${VIDEO_SOURCES[source]}/${videoID}" frameborder="0" allow="autoplay; fullscreen picture-in-picture" allowfullscreen></iframe>`,
          }}
        />
      </div>
    </section>
  ) : null;
};

export default CustomVideoPlayer;

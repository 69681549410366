import {
  ANDROID_URL_SCHEME,
  IOS_URL_SCHEME,
  BUSINESS_APP_GOOGLE_PLAY_STORE_URL,
  BUSINESS_APP_IOS_APP_STORE_URL,
  STARTER_APP_GOOGLE_PLAY_STORE_URL,
  STARTER_APP_IOS_APP_STORE_URL,
  STARTER_APP_ANDROID_URL_SCHEME,
  STARTER_APP_IOS_URL_SCHEME,
} from "data/config";
import { AppDownloadType } from "types";

export const REDIRECT_URL = {
  [AppDownloadType.BusinessApp]: {
    scheme_url: {
      google: ANDROID_URL_SCHEME,
      ios: IOS_URL_SCHEME,
    },
    store_url: {
      google: BUSINESS_APP_GOOGLE_PLAY_STORE_URL,
      ios: BUSINESS_APP_IOS_APP_STORE_URL,
    },
  },
  [AppDownloadType.StarterApp]: {
    scheme_url: {
      google: STARTER_APP_ANDROID_URL_SCHEME,
      ios: STARTER_APP_IOS_URL_SCHEME,
    },
    store_url: {
      google: STARTER_APP_GOOGLE_PLAY_STORE_URL,
      ios: STARTER_APP_IOS_APP_STORE_URL,
    },
  },
};

import { AxiosError } from "axios";
import { notifyError } from "brass-ui-kit";
import { captureException as SentryCaptureException } from "@sentry/gatsby";

const reduceMessages = (obj: any = {}) =>
  Object.keys(obj).reduce(
    (acc: any[], key: any) => acc.concat(`${obj[key]}`),
    []
  );

const getErrorMessage = (action = {}): string[] => {
  const { error = {} }: any = action;

  if (error.status === 422) {
    if (Object.keys(error.source).length > 0) {
      const firstError: any = Object.values(error.source)[0];
      return [firstError];
    }
  }
  if (error.status >= 400 && error.status < 500) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: any) => reduceMessages(err)).flat();
    }
    if (error) return [error.description];
  }
  return [error?.description || "Something went wrong"];
};

export const handleError = (err: AxiosError<any>): void => {
  if (err) {
    const errorMessage = getErrorMessage({ error: err?.response?.data?.error });
    const combinedErrorMessage =
      errorMessage.length > 1
        ? errorMessage.join(", ")
        : errorMessage.toString();

    if (combinedErrorMessage) {
      notifyError(combinedErrorMessage);
    }
  }

  // Report error to sentry
  SentryCaptureException(err);
  // throw err;
};

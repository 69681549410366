import React, { RefObject } from "react";
import { IconCircleArrow } from "assets/media/svgs";

import * as styles from "./Scrollbar.module.scss";

interface ScrollbarProps {
  contentRef: RefObject<HTMLDivElement>;
  hasButton?: boolean;
}

enum ThumbPosition {
  Left = "left",
  Right = "right",
}

const Scrollbar: React.FC<ScrollbarProps> = ({ contentRef, hasButton }) => {
  const thumbScrollAMount = 500;

  const [state, setState] = React.useState({
    thumbWidth: 0,
    initialScroll: 0,
    isDragging: false,
    scrollStartPosition: 0,
    scrollWidth: 0,
  });

  const scrollTrackRef = React.useRef<HTMLDivElement>(null);
  const scrollThumbRef = React.useRef<HTMLDivElement>(null);
  const observer = React.useRef<ResizeObserver | null>(null);

  const maxScrollWidth = React.useMemo(() => {
    if (scrollTrackRef.current)
      return Math.floor(scrollTrackRef.current.clientWidth - state.thumbWidth);
  }, [state.thumbWidth, scrollTrackRef.current]);

  const handleStateUpdate = (newState: Partial<typeof state>) => {
    setState((state) => ({ ...state, ...newState }));
  };

  const handleResize = (ref: HTMLDivElement, trackSize: number) => {
    const { clientWidth, scrollWidth } = ref;
    handleStateUpdate({
      thumbWidth: Math.max((clientWidth / scrollWidth) * trackSize, 20),
    });
  };

  const handleThumbPosition = React.useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }

    const { scrollLeft: contentLeft, scrollWidth: contentWidth } =
      contentRef.current;
    const { clientWidth: trackWidth } = scrollTrackRef.current;
    let newPos = (+contentLeft / +contentWidth) * trackWidth;
    newPos = Math.min(newPos, trackWidth - state.thumbWidth);
    handleStateUpdate({ scrollWidth: Math.floor(newPos) });
    const thumb = scrollThumbRef.current;
    thumb.style.left = `${newPos}px`;
  }, []);

  const handleScrollButton = (direction: ThumbPosition) => {
    const { current } = contentRef;
    if (current) {
      const scrollAmount =
        direction === ThumbPosition.Right
          ? thumbScrollAMount
          : -thumbScrollAMount;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handleTrackClick = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { current: trackCurrent } = scrollTrackRef;
      const { current: contentCurrent } = contentRef;
      if (trackCurrent && contentCurrent) {
        const { clientX } = e;
        const target = e.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const trackLeft = rect.left;
        const thumbOffset = -(state.thumbWidth / 2);
        const clickRatio =
          (clientX - trackLeft + thumbOffset) / trackCurrent.clientWidth;
        const scrollAmount = Math.floor(
          clickRatio * contentCurrent.scrollWidth
        );
        contentCurrent.scrollTo({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    },
    [state.thumbWidth]
  );

  const handleThumbMousedown = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    handleStateUpdate({ scrollStartPosition: e.clientX });
    if (contentRef.current)
      handleStateUpdate({
        initialScroll: contentRef.current.scrollLeft,
        isDragging: true,
      });
  }, []);

  const handleThumbMouseup = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (state.isDragging) {
        handleStateUpdate({ isDragging: false });
      }
    },
    [state.isDragging]
  );

  const handleThumbMousemove = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (state.isDragging && contentRef.current) {
        const { scrollWidth, offsetWidth } = contentRef.current;

        const deltaX =
          (e.clientX - state.scrollStartPosition) *
          (offsetWidth / state.thumbWidth);

        const newScroll = Math.min(
          state.initialScroll + deltaX,
          scrollWidth - offsetWidth
        );

        contentRef.current.scrollLeft = newScroll;
      }
    },
    [state.isDragging, state.scrollStartPosition, state.thumbWidth]
  );

  React.useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientWidth: trackSize } = scrollTrackRef.current;
      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });
      observer.current.observe(ref);
      ref.addEventListener("scroll", handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener("scroll", handleThumbPosition);
      };
    }
  }, [state.thumbWidth]);

  React.useEffect(() => {
    document.addEventListener("mousemove", handleThumbMousemove);
    document.addEventListener("mouseup", handleThumbMouseup);
    document.addEventListener("mouseleave", handleThumbMouseup);
    return () => {
      document.removeEventListener("mousemove", handleThumbMousemove);
      document.removeEventListener("mouseup", handleThumbMouseup);
      document.removeEventListener("mouseleave", handleThumbMouseup);
    };
  }, [handleThumbMousemove, handleThumbMouseup]);

  return (
    <div className={styles.Scrollbar}>
      <div className={styles.Scrollbar_scroll}>
        <div
          className={styles.Scrollbar_scroll_track}
          ref={scrollTrackRef}
          onClick={handleTrackClick}
          style={{ cursor: state.isDragging ? "grabbing" : "pointer" }}
        />
        <div
          className={styles.Scrollbar_scroll_thumb}
          ref={scrollThumbRef}
          onMouseDown={handleThumbMousedown}
          style={{
            width: `${state.thumbWidth}px`,
            cursor: state.isDragging ? "grabbing" : "grab",
          }}
        />
      </div>
      {hasButton && (
        <div className={styles.Scrollbar_buttons}>
          <button
            className={styles.Scrollbar_buttons_btn}
            onClick={() => handleScrollButton(ThumbPosition.Left)}
            disabled={state.scrollWidth === 0}
          >
            <IconCircleArrow />
          </button>
          <button
            className={styles.Scrollbar_buttons_btn}
            disabled={state.scrollWidth === maxScrollWidth}
            onClick={() => handleScrollButton(ThumbPosition.Right)}
          >
            <IconCircleArrow />
          </button>
        </div>
      )}
    </div>
  );
};

export default Scrollbar;

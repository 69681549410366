import { AUTH_EVENTS } from "data/events";
import Amplitude from "./amplitude";

class AppAnalytics {
  ANALYTICS_PLUGINS: any = {
    amplitude: Amplitude,
  };

  // Initializes all analytics plugins
  initialize = () => {
    Object.values(this.ANALYTICS_PLUGINS).forEach((plugin: any) => {
      plugin.initialize();
    });
  };

  //  Indentify logged in user
  identifyUser = (userId: string) => {
    Object.values(this.ANALYTICS_PLUGINS).forEach((plugin: any) => {
      plugin.identifyUser(userId);
    });
  };

  clearUser = (data: any, callback: () => void = () => {}) => {
    Object.values(this.ANALYTICS_PLUGINS).forEach((plugin: any) => {
      plugin.clearUser();
    });
  };

  //  Log event to all analytics plugins
  logAll = (event: string, data: any, callback: () => void) => {
    Object.values(this.ANALYTICS_PLUGINS).forEach((plugin: any) => {
      plugin.log(event, data, callback);
    });
  };

  // Log event to specific analytic plugin
  logOne = (plugin: string, event: string, data: any, callback: () => void) => {
    this.ANALYTICS_PLUGINS[plugin].log(event, data, callback);
  };

  // // Log event to specified analytic plugins
  // log = (plugins: [string], event: string, data: any, callback: () => void) => {
  //   plugins.forEach((plugin: any) => {
  //     this.ANALYTICS_PLUGINS[plugin].log(event, data, callback);
  //   });
  // };
}

export default new AppAnalytics();

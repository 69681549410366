import React from "react";
import { IconArrowForward } from "assets/media/svgs";
import { Link } from "gatsby";

import * as styles from "./Banner.module.scss";

interface Banner {
  title: string;
  path: string;
}

const Banner: React.FC<Banner> = ({ title, path }) => {
  return (
    <nav className={styles.Banner}>
      <Link to={`/${path}`} className={styles.Banner_container}>
        <div className={styles.Banner_badge}>NEW</div>
        <p className={styles.Banner_text}>{title}</p>
        <IconArrowForward className={styles.Banner_icon} />
      </Link>
    </nav>
  );
};

export default Banner;

import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { WithData, Speaker } from "types";
import * as styles from "./Quote.module.scss";

interface QuoteProps {
  quote: string;
  company_name: string;
  speaker: WithData<Speaker>;
  bg_img: IGatsbyImageData;
  user_img: IGatsbyImageData;
}

const Quote: React.FC<QuoteProps> = ({
  quote,
  company_name,
  speaker,
  bg_img,
  user_img,
}) => {
  return (
    <div className={styles.Quote}>
      <GatsbyImage
        image={bg_img}
        alt={company_name}
        className={styles.Quote_img}
      />
      <div className={styles.Quote_card}>
        <p className={styles.Quote_card_desc}>“{quote}”</p>
        <div className={styles.Quote_card_user}>
          <GatsbyImage
            image={user_img}
            alt={company_name}
            className={styles.Quote_card_user_img}
          />
          <div className={styles.Quote_card_user_details}>
            <h4 className={styles.Quote_card_user_details_name}>
              {speaker.data.attributes.name}
            </h4>
            <p className={styles.Quote_card_user_details_company}>
              {speaker.data.attributes.role}, {company_name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;

import axios from "axios";
import * as Sentry from "@sentry/gatsby";

import { API_URL, API_KEY } from "data/config";

const BrassService = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

BrassService.interceptors.request.use((req) => {
  req.headers!["Authorization"] = `Bearer ${API_KEY}`;
  return req;
});

BrassService.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    Sentry.captureException(err);
    return Promise.reject(err);
  }
);

export default BrassService;

import React, { FC } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import { FOOTER_CONFIG } from "./config";
import {
  BrandLogomark,
  IconAndroidPlaystore,
  IconFooterCaret,
  IconIosAppStore,
} from "assets/media/svgs";
import appData from "../../../../app.json";
import { LayoutContext } from "../../../context";
import * as styles from "./Footer.module.scss";
import { LUNCHPAD_APP_SIGN_UP_URL } from "data/config";
import { AuthActionType } from "types";

interface FooterProps {
  location: Location;
}

const Footer: FC<FooterProps> = ({ location }) => {
  const { isFooterButtonSticky, openAppDownloadModal, openAuthActionModal } =
    React.useContext(LayoutContext);
  const [activeSubSection, setActiveSubSection] = React.useState("Industry");

  const isActionModalOpen = openAppDownloadModal || openAuthActionModal;

  const isLaunchpad = location.pathname.includes("launchpad");

  const floatingButtonText = isLaunchpad
    ? "Start your company"
    : "Open an account in 10 minutes";

  const { updateContext } = React.useContext(LayoutContext);

  return (
    <section
      className={cx(styles.Footer, {
        [styles.Footer_withStickyButton]: isFooterButtonSticky,
      })}
    >
      <div className={styles.Footer_container}>
        <section className={styles.Footer_container_grid}>
          {FOOTER_CONFIG.sections.map(({ label, subItems }) => {
            return (
              <div className={styles.Footer_container_grid_col} key={label}>
                <header
                  className={styles.Footer_container_grid_col_header}
                  onClick={() => {
                    if (activeSubSection === label) {
                      return setActiveSubSection("");
                    }
                    setActiveSubSection(label);
                  }}
                >
                  <h6 className={styles.Footer_container_grid_col_header_ttl}>
                    {label}
                  </h6>
                  <IconFooterCaret
                    className={cx(
                      styles.Footer_container_grid_col_header_caret,
                      {
                        [styles.Footer_container_grid_col_header_caret__active]:
                          label === activeSubSection,
                      }
                    )}
                  />
                </header>
                <div
                  className={cx(styles.Footer_container_grid_col_links, {
                    [styles.Footer_container_grid_col_links__active]:
                      label === activeSubSection,
                  })}
                >
                  {subItems.map(({ label, route, isExternal = false }) => {
                    if (isExternal) {
                      return (
                        <a
                          className={
                            styles.Footer_container_grid_col_links_item
                          }
                          key={label}
                          href={route}
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          {label}
                        </a>
                      );
                    }
                    return (
                      <Link
                        className={styles.Footer_container_grid_col_links_item}
                        key={label}
                        to={route}
                      >
                        {label}
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>
        <section className={styles.Footer_subSection}>
          <div className={styles.Footer_subSection_logoSection}>
            <BrandLogomark
              className={styles.Footer_subSection_logoSection_logo}
            />
            <p>Big starts small.</p>
          </div>
          <div>
            <h5 className={styles.Footer_subSection_platforms_ttl}>
              Available on web and also on
            </h5>
            <div className={styles.Footer_subSection_platforms_grid}>
              <a href={appData.apps.playstore} target="_blank">
                <IconAndroidPlaystore />
              </a>
              <a href={appData.apps.appstore} target="_blank">
                <IconIosAppStore />
              </a>
            </div>
          </div>
        </section>
        <section className={styles.Footer_disclaimer}>
          Banking services backed by Wema Bank & Titan Trust Bank and regulated
          by the Central Bank of Nigeria.
          <br /> Brass web and mobile applications are properties 
          of Copper Brass Limited, a company registered with the Corporate
          Affairs Commission with RC Number: 7483800.
        </section>
      </div>
      <a
        className={cx(styles.Footer_signupBtn, {
          [styles.Footer_signupBtn_sticky]:
            isFooterButtonSticky && !isActionModalOpen,
        })}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (isLaunchpad) window.open(LUNCHPAD_APP_SIGN_UP_URL, "_blank");
          else
            updateContext({
              openAuthActionModal: true,
              authActionModalType: AuthActionType.SignUp,
            });
        }}
      >
        <span>{floatingButtonText}</span>
      </a>
    </section>
  );
};

export default Footer;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-capital-index-tsx": () => import("./../../../src/pages/capital/index.tsx" /* webpackChunkName: "component---src-pages-capital-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-customers-index-tsx": () => import("./../../../src/pages/customers/index.tsx" /* webpackChunkName: "component---src-pages-customers-index-tsx" */),
  "component---src-pages-ebook-index-tsx": () => import("./../../../src/pages/ebook/index.tsx" /* webpackChunkName: "component---src-pages-ebook-index-tsx" */),
  "component---src-pages-guides-index-tsx": () => import("./../../../src/pages/guides/index.tsx" /* webpackChunkName: "component---src-pages-guides-index-tsx" */),
  "component---src-pages-help-index-tsx": () => import("./../../../src/pages/help/index.tsx" /* webpackChunkName: "component---src-pages-help-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internet-companies-index-tsx": () => import("./../../../src/pages/internet-companies/index.tsx" /* webpackChunkName: "component---src-pages-internet-companies-index-tsx" */),
  "component---src-pages-launchpad-help-index-tsx": () => import("./../../../src/pages/launchpad-help/index.tsx" /* webpackChunkName: "component---src-pages-launchpad-help-index-tsx" */),
  "component---src-pages-launchpad-index-tsx": () => import("./../../../src/pages/launchpad/index.tsx" /* webpackChunkName: "component---src-pages-launchpad-index-tsx" */),
  "component---src-pages-legal-brass-capital-index-tsx": () => import("./../../../src/pages/legal/brass-capital/index.tsx" /* webpackChunkName: "component---src-pages-legal-brass-capital-index-tsx" */),
  "component---src-pages-legal-cards-index-tsx": () => import("./../../../src/pages/legal/cards/index.tsx" /* webpackChunkName: "component---src-pages-legal-cards-index-tsx" */),
  "component---src-pages-legal-privacy-policy-index-tsx": () => import("./../../../src/pages/legal/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-index-tsx" */),
  "component---src-pages-legal-terms-of-service-index-tsx": () => import("./../../../src/pages/legal/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-service-index-tsx" */),
  "component---src-pages-payroll-index-tsx": () => import("./../../../src/pages/payroll/index.tsx" /* webpackChunkName: "component---src-pages-payroll-index-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-retail-index-tsx": () => import("./../../../src/pages/retail/index.tsx" /* webpackChunkName: "component---src-pages-retail-index-tsx" */),
  "component---src-pages-why-brass-index-tsx": () => import("./../../../src/pages/why-brass/index.tsx" /* webpackChunkName: "component---src-pages-why-brass-index-tsx" */),
  "component---src-templates-blog-category-index-tsx": () => import("./../../../src/templates/blog-category/index.tsx" /* webpackChunkName: "component---src-templates-blog-category-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blog-post/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-careers-index-tsx": () => import("./../../../src/templates/careers/index.tsx" /* webpackChunkName: "component---src-templates-careers-index-tsx" */),
  "component---src-templates-case-study-index-tsx": () => import("./../../../src/templates/case-study/index.tsx" /* webpackChunkName: "component---src-templates-case-study-index-tsx" */),
  "component---src-templates-guide-category-index-tsx": () => import("./../../../src/templates/guide-category/index.tsx" /* webpackChunkName: "component---src-templates-guide-category-index-tsx" */),
  "component---src-templates-guide-index-tsx": () => import("./../../../src/templates/guide/index.tsx" /* webpackChunkName: "component---src-templates-guide-index-tsx" */)
}


// extracted by mini-css-extract-plugin
export var AppDownloadModal = "AppDownloadModal-module--AppDownloadModal--Mx6-M";
export var AppDownloadModal_btn = "AppDownloadModal-module--AppDownloadModal_btn--AWSva";
export var AppDownloadModal_container = "AppDownloadModal-module--AppDownloadModal_container--uUGBK";
export var AppDownloadModal_content = "AppDownloadModal-module--AppDownloadModal_content--UGMNw";
export var AppDownloadModal_content_btns = "AppDownloadModal-module--AppDownloadModal_content_btns--vVWxw";
export var AppDownloadModal_content_btns_btn = "AppDownloadModal-module--AppDownloadModal_content_btns_btn--RFkUn";
export var AppDownloadModal_content_img = "AppDownloadModal-module--AppDownloadModal_content_img--Oz5jW";
export var AppDownloadModal_content_info = "AppDownloadModal-module--AppDownloadModal_content_info--OWGIs";
export var AppDownloadModal_content_info_text = "AppDownloadModal-module--AppDownloadModal_content_info_text--1xAfh";
export var AppDownloadModal_content_info_title = "AppDownloadModal-module--AppDownloadModal_content_info_title--mieNn";
export var slideInLeft = "AppDownloadModal-module--slideInLeft--QMX5g";
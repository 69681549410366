// extracted by mini-css-extract-plugin
export var QualitiesList = "QualitiesList-module--QualitiesList--SXCAj";
export var QualitiesList_container = "QualitiesList-module--QualitiesList_container--vYBLq";
export var QualitiesList_item = "QualitiesList-module--QualitiesList_item--qRWvb";
export var QualitiesList_item_body = "QualitiesList-module--QualitiesList_item_body--XojrQ";
export var QualitiesList_item_desc = "QualitiesList-module--QualitiesList_item_desc--vjz0k";
export var QualitiesList_item_image = "QualitiesList-module--QualitiesList_item_image--57xbO";
export var QualitiesList_item_link = "QualitiesList-module--QualitiesList_item_link--rVJRP";
export var QualitiesList_item_text = "QualitiesList-module--QualitiesList_item_text--Ir7Km";
export var QualitiesList_item_title = "QualitiesList-module--QualitiesList_item_title--Gds1T";
export var QualitiesList_theme_dark = "QualitiesList-module--QualitiesList_theme_dark--vme55";
export var container = "QualitiesList-module--container---pMvD";
export var gatsbyImageWrapperConstrained = "QualitiesList-module--gatsby-image-wrapper-constrained--qttOf";
export var gridSpan1010 = "QualitiesList-module--grid-span-10-10--7Au47";
export var gridSpan1011 = "QualitiesList-module--grid-span-10-11--XBxFb";
export var gridSpan102 = "QualitiesList-module--grid-span-10-2--2wspJ";
export var gridSpan103 = "QualitiesList-module--grid-span-10-3--GfgxJ";
export var gridSpan104 = "QualitiesList-module--grid-span-10-4--Nh6hW";
export var gridSpan105 = "QualitiesList-module--grid-span-10-5--geGX+";
export var gridSpan106 = "QualitiesList-module--grid-span-10-6--7X902";
export var gridSpan107 = "QualitiesList-module--grid-span-10-7--HlaXj";
export var gridSpan108 = "QualitiesList-module--grid-span-10-8--gHq1K";
export var gridSpan109 = "QualitiesList-module--grid-span-10-9--zpJAH";
export var gridSpan110 = "QualitiesList-module--grid-span-1-10--jslno";
export var gridSpan111 = "QualitiesList-module--grid-span-1-11--uhAXI";
export var gridSpan1110 = "QualitiesList-module--grid-span-11-10--U4TPq";
export var gridSpan1111 = "QualitiesList-module--grid-span-11-11--REwfv";
export var gridSpan112 = "QualitiesList-module--grid-span-11-2--fFg7p";
export var gridSpan113 = "QualitiesList-module--grid-span-11-3--9HseH";
export var gridSpan114 = "QualitiesList-module--grid-span-11-4--HwUQe";
export var gridSpan115 = "QualitiesList-module--grid-span-11-5--MacHC";
export var gridSpan116 = "QualitiesList-module--grid-span-11-6--T8Gry";
export var gridSpan117 = "QualitiesList-module--grid-span-11-7--YVjge";
export var gridSpan118 = "QualitiesList-module--grid-span-11-8--rHA2k";
export var gridSpan119 = "QualitiesList-module--grid-span-11-9--Dw3F2";
export var gridSpan12 = "QualitiesList-module--grid-span-1-2--puiZu";
export var gridSpan1210 = "QualitiesList-module--grid-span-12-10--EUUi1";
export var gridSpan1211 = "QualitiesList-module--grid-span-12-11--GmZ5N";
export var gridSpan122 = "QualitiesList-module--grid-span-12-2--DHruh";
export var gridSpan123 = "QualitiesList-module--grid-span-12-3--KA4BE";
export var gridSpan124 = "QualitiesList-module--grid-span-12-4--XGrXP";
export var gridSpan125 = "QualitiesList-module--grid-span-12-5--WKeCP";
export var gridSpan126 = "QualitiesList-module--grid-span-12-6--Ghpop";
export var gridSpan127 = "QualitiesList-module--grid-span-12-7--mG14q";
export var gridSpan128 = "QualitiesList-module--grid-span-12-8--4M6aV";
export var gridSpan129 = "QualitiesList-module--grid-span-12-9--O1GVz";
export var gridSpan13 = "QualitiesList-module--grid-span-1-3--jGOgg";
export var gridSpan14 = "QualitiesList-module--grid-span-1-4--K+QdM";
export var gridSpan15 = "QualitiesList-module--grid-span-1-5--m5QAY";
export var gridSpan16 = "QualitiesList-module--grid-span-1-6--YESve";
export var gridSpan17 = "QualitiesList-module--grid-span-1-7--b0rpr";
export var gridSpan18 = "QualitiesList-module--grid-span-1-8--Yon+N";
export var gridSpan19 = "QualitiesList-module--grid-span-1-9--TFhxX";
export var gridSpan210 = "QualitiesList-module--grid-span-2-10--rUNnU";
export var gridSpan211 = "QualitiesList-module--grid-span-2-11--Bp2kS";
export var gridSpan22 = "QualitiesList-module--grid-span-2-2--lOHWg";
export var gridSpan23 = "QualitiesList-module--grid-span-2-3--iJFdR";
export var gridSpan24 = "QualitiesList-module--grid-span-2-4--kny1d";
export var gridSpan25 = "QualitiesList-module--grid-span-2-5--Ono-0";
export var gridSpan26 = "QualitiesList-module--grid-span-2-6--2pFu+";
export var gridSpan27 = "QualitiesList-module--grid-span-2-7--kVZhY";
export var gridSpan28 = "QualitiesList-module--grid-span-2-8--GgKs1";
export var gridSpan29 = "QualitiesList-module--grid-span-2-9--Ls9dQ";
export var gridSpan310 = "QualitiesList-module--grid-span-3-10--yXuwy";
export var gridSpan311 = "QualitiesList-module--grid-span-3-11--CX3Pm";
export var gridSpan32 = "QualitiesList-module--grid-span-3-2--7MTQQ";
export var gridSpan33 = "QualitiesList-module--grid-span-3-3--0EWCv";
export var gridSpan34 = "QualitiesList-module--grid-span-3-4--a2oMM";
export var gridSpan35 = "QualitiesList-module--grid-span-3-5--74FJj";
export var gridSpan36 = "QualitiesList-module--grid-span-3-6--Z-KPF";
export var gridSpan37 = "QualitiesList-module--grid-span-3-7--hJEsC";
export var gridSpan38 = "QualitiesList-module--grid-span-3-8---oSbj";
export var gridSpan39 = "QualitiesList-module--grid-span-3-9--afsxv";
export var gridSpan410 = "QualitiesList-module--grid-span-4-10--zlzjz";
export var gridSpan411 = "QualitiesList-module--grid-span-4-11--jyEuX";
export var gridSpan42 = "QualitiesList-module--grid-span-4-2--m0fyT";
export var gridSpan43 = "QualitiesList-module--grid-span-4-3--PoS56";
export var gridSpan44 = "QualitiesList-module--grid-span-4-4--7p4HF";
export var gridSpan45 = "QualitiesList-module--grid-span-4-5--bkVc6";
export var gridSpan46 = "QualitiesList-module--grid-span-4-6--vQPEv";
export var gridSpan47 = "QualitiesList-module--grid-span-4-7--LB0GN";
export var gridSpan48 = "QualitiesList-module--grid-span-4-8--kpUVg";
export var gridSpan49 = "QualitiesList-module--grid-span-4-9--ioMpv";
export var gridSpan510 = "QualitiesList-module--grid-span-5-10--E26fD";
export var gridSpan511 = "QualitiesList-module--grid-span-5-11--PD2rI";
export var gridSpan52 = "QualitiesList-module--grid-span-5-2--EzW-g";
export var gridSpan53 = "QualitiesList-module--grid-span-5-3--sqoeO";
export var gridSpan54 = "QualitiesList-module--grid-span-5-4--70Fxa";
export var gridSpan55 = "QualitiesList-module--grid-span-5-5--6GC6X";
export var gridSpan56 = "QualitiesList-module--grid-span-5-6---koyc";
export var gridSpan57 = "QualitiesList-module--grid-span-5-7--6fI9e";
export var gridSpan58 = "QualitiesList-module--grid-span-5-8--PgXj0";
export var gridSpan59 = "QualitiesList-module--grid-span-5-9--7Fliq";
export var gridSpan610 = "QualitiesList-module--grid-span-6-10--AY177";
export var gridSpan611 = "QualitiesList-module--grid-span-6-11--X8xIf";
export var gridSpan62 = "QualitiesList-module--grid-span-6-2--2csly";
export var gridSpan63 = "QualitiesList-module--grid-span-6-3--jIZ2L";
export var gridSpan64 = "QualitiesList-module--grid-span-6-4--k1l3v";
export var gridSpan65 = "QualitiesList-module--grid-span-6-5--zmElW";
export var gridSpan66 = "QualitiesList-module--grid-span-6-6--2Jb67";
export var gridSpan67 = "QualitiesList-module--grid-span-6-7--4d4OR";
export var gridSpan68 = "QualitiesList-module--grid-span-6-8--MWAKD";
export var gridSpan69 = "QualitiesList-module--grid-span-6-9--PWeSi";
export var gridSpan710 = "QualitiesList-module--grid-span-7-10--BCBM6";
export var gridSpan711 = "QualitiesList-module--grid-span-7-11--m12XH";
export var gridSpan72 = "QualitiesList-module--grid-span-7-2--iDW4G";
export var gridSpan73 = "QualitiesList-module--grid-span-7-3--FWIbU";
export var gridSpan74 = "QualitiesList-module--grid-span-7-4--Uaqk7";
export var gridSpan75 = "QualitiesList-module--grid-span-7-5--glbJX";
export var gridSpan76 = "QualitiesList-module--grid-span-7-6--9cJPh";
export var gridSpan77 = "QualitiesList-module--grid-span-7-7--U-g3F";
export var gridSpan78 = "QualitiesList-module--grid-span-7-8--Rlqgb";
export var gridSpan79 = "QualitiesList-module--grid-span-7-9--K-Tlh";
export var gridSpan810 = "QualitiesList-module--grid-span-8-10--OrUWi";
export var gridSpan811 = "QualitiesList-module--grid-span-8-11--xlfH0";
export var gridSpan82 = "QualitiesList-module--grid-span-8-2--ZWD2n";
export var gridSpan83 = "QualitiesList-module--grid-span-8-3--sDiS0";
export var gridSpan84 = "QualitiesList-module--grid-span-8-4--BPkjS";
export var gridSpan85 = "QualitiesList-module--grid-span-8-5--EVshJ";
export var gridSpan86 = "QualitiesList-module--grid-span-8-6--339KG";
export var gridSpan87 = "QualitiesList-module--grid-span-8-7--inqJW";
export var gridSpan88 = "QualitiesList-module--grid-span-8-8--kB+CV";
export var gridSpan89 = "QualitiesList-module--grid-span-8-9--6l20y";
export var gridSpan910 = "QualitiesList-module--grid-span-9-10--SIqWo";
export var gridSpan911 = "QualitiesList-module--grid-span-9-11--9t9B-";
export var gridSpan92 = "QualitiesList-module--grid-span-9-2--kq7DP";
export var gridSpan93 = "QualitiesList-module--grid-span-9-3--hHxgT";
export var gridSpan94 = "QualitiesList-module--grid-span-9-4--HleJb";
export var gridSpan95 = "QualitiesList-module--grid-span-9-5--Mby9Z";
export var gridSpan96 = "QualitiesList-module--grid-span-9-6--LxAKn";
export var gridSpan97 = "QualitiesList-module--grid-span-9-7--MDkbn";
export var gridSpan98 = "QualitiesList-module--grid-span-9-8--WEoy3";
export var gridSpan99 = "QualitiesList-module--grid-span-9-9--cjcKY";
export var textBreak = "QualitiesList-module--text-break--zwgfP";
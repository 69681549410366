// extracted by mini-css-extract-plugin
export var Footer = "Footer-module--Footer--gLcO0";
export var Footer_container = "Footer-module--Footer_container--t85E9";
export var Footer_container_grid = "Footer-module--Footer_container_grid--Sf+S3";
export var Footer_container_grid_col = "Footer-module--Footer_container_grid_col--a0jYb";
export var Footer_container_grid_col_header = "Footer-module--Footer_container_grid_col_header--i+RAO";
export var Footer_container_grid_col_header_caret = "Footer-module--Footer_container_grid_col_header_caret--tHb70";
export var Footer_container_grid_col_header_caret__active = "Footer-module--Footer_container_grid_col_header_caret__active--8a9Gs";
export var Footer_container_grid_col_header_ttl = "Footer-module--Footer_container_grid_col_header_ttl---vaC6";
export var Footer_container_grid_col_links = "Footer-module--Footer_container_grid_col_links--CpZ3V";
export var Footer_container_grid_col_links__active = "Footer-module--Footer_container_grid_col_links__active--juLTW";
export var Footer_container_grid_col_links_item = "Footer-module--Footer_container_grid_col_links_item---qYJW";
export var Footer_disclaimer = "Footer-module--Footer_disclaimer--S1YXH";
export var Footer_signupBtn = "Footer-module--Footer_signupBtn--Y-Lla";
export var Footer_signupBtn_sticky = "Footer-module--Footer_signupBtn_sticky--HeRZW";
export var Footer_subSection = "Footer-module--Footer_subSection--MX75t";
export var Footer_subSection_logoSection = "Footer-module--Footer_subSection_logoSection--SKzKb";
export var Footer_subSection_logoSection_logo = "Footer-module--Footer_subSection_logoSection_logo--D1Yhj";
export var Footer_subSection_platforms_grid = "Footer-module--Footer_subSection_platforms_grid--qAZh4";
export var Footer_subSection_platforms_ttl = "Footer-module--Footer_subSection_platforms_ttl--WTGF3";
export var Footer_withStickyButton = "Footer-module--Footer_withStickyButton--hRKr+";
export var container = "Footer-module--container--pXCDq";
export var gatsbyImageWrapperConstrained = "Footer-module--gatsby-image-wrapper-constrained--9C3up";
export var gridSpan1010 = "Footer-module--grid-span-10-10--clShl";
export var gridSpan1011 = "Footer-module--grid-span-10-11--EVs1c";
export var gridSpan102 = "Footer-module--grid-span-10-2--EObjv";
export var gridSpan103 = "Footer-module--grid-span-10-3--zkkwi";
export var gridSpan104 = "Footer-module--grid-span-10-4--g0eaC";
export var gridSpan105 = "Footer-module--grid-span-10-5--F2Byk";
export var gridSpan106 = "Footer-module--grid-span-10-6--ZwgKF";
export var gridSpan107 = "Footer-module--grid-span-10-7--ASByY";
export var gridSpan108 = "Footer-module--grid-span-10-8--D5INf";
export var gridSpan109 = "Footer-module--grid-span-10-9--n-OYo";
export var gridSpan110 = "Footer-module--grid-span-1-10--Hahg0";
export var gridSpan111 = "Footer-module--grid-span-1-11--l30bE";
export var gridSpan1110 = "Footer-module--grid-span-11-10--6Fu6n";
export var gridSpan1111 = "Footer-module--grid-span-11-11--we2+i";
export var gridSpan112 = "Footer-module--grid-span-11-2--K+Ohd";
export var gridSpan113 = "Footer-module--grid-span-11-3--P3d-q";
export var gridSpan114 = "Footer-module--grid-span-11-4--H+jmk";
export var gridSpan115 = "Footer-module--grid-span-11-5--wrYs4";
export var gridSpan116 = "Footer-module--grid-span-11-6--B71G3";
export var gridSpan117 = "Footer-module--grid-span-11-7--W1cK6";
export var gridSpan118 = "Footer-module--grid-span-11-8---Mvyr";
export var gridSpan119 = "Footer-module--grid-span-11-9--kj2kT";
export var gridSpan12 = "Footer-module--grid-span-1-2--sujUJ";
export var gridSpan1210 = "Footer-module--grid-span-12-10--iNB8P";
export var gridSpan1211 = "Footer-module--grid-span-12-11---rwUp";
export var gridSpan122 = "Footer-module--grid-span-12-2--KSszH";
export var gridSpan123 = "Footer-module--grid-span-12-3--C0OuZ";
export var gridSpan124 = "Footer-module--grid-span-12-4--7+D07";
export var gridSpan125 = "Footer-module--grid-span-12-5--X4nsm";
export var gridSpan126 = "Footer-module--grid-span-12-6--QCLW8";
export var gridSpan127 = "Footer-module--grid-span-12-7--4LtcO";
export var gridSpan128 = "Footer-module--grid-span-12-8--bPJb+";
export var gridSpan129 = "Footer-module--grid-span-12-9--sVz0s";
export var gridSpan13 = "Footer-module--grid-span-1-3--lQBv3";
export var gridSpan14 = "Footer-module--grid-span-1-4--btxf+";
export var gridSpan15 = "Footer-module--grid-span-1-5--U+O+T";
export var gridSpan16 = "Footer-module--grid-span-1-6--T2AIi";
export var gridSpan17 = "Footer-module--grid-span-1-7--0vnIb";
export var gridSpan18 = "Footer-module--grid-span-1-8--xWcht";
export var gridSpan19 = "Footer-module--grid-span-1-9--XDDL8";
export var gridSpan210 = "Footer-module--grid-span-2-10--JIOkE";
export var gridSpan211 = "Footer-module--grid-span-2-11--vyQC-";
export var gridSpan22 = "Footer-module--grid-span-2-2--SovRg";
export var gridSpan23 = "Footer-module--grid-span-2-3--FyoSL";
export var gridSpan24 = "Footer-module--grid-span-2-4--nQcRd";
export var gridSpan25 = "Footer-module--grid-span-2-5--Lgui5";
export var gridSpan26 = "Footer-module--grid-span-2-6--ynwXr";
export var gridSpan27 = "Footer-module--grid-span-2-7--7bEeW";
export var gridSpan28 = "Footer-module--grid-span-2-8--jdBUd";
export var gridSpan29 = "Footer-module--grid-span-2-9--dS6ZV";
export var gridSpan310 = "Footer-module--grid-span-3-10---S2Ln";
export var gridSpan311 = "Footer-module--grid-span-3-11--PN+-K";
export var gridSpan32 = "Footer-module--grid-span-3-2--5UbRU";
export var gridSpan33 = "Footer-module--grid-span-3-3--dlKDO";
export var gridSpan34 = "Footer-module--grid-span-3-4--kgpAp";
export var gridSpan35 = "Footer-module--grid-span-3-5--yZ7vs";
export var gridSpan36 = "Footer-module--grid-span-3-6--ThUXh";
export var gridSpan37 = "Footer-module--grid-span-3-7--zmr2u";
export var gridSpan38 = "Footer-module--grid-span-3-8--R2Ve7";
export var gridSpan39 = "Footer-module--grid-span-3-9--BWeKX";
export var gridSpan410 = "Footer-module--grid-span-4-10--3F3qc";
export var gridSpan411 = "Footer-module--grid-span-4-11--LjWM6";
export var gridSpan42 = "Footer-module--grid-span-4-2--ICkPD";
export var gridSpan43 = "Footer-module--grid-span-4-3--e+MdT";
export var gridSpan44 = "Footer-module--grid-span-4-4--iOLlE";
export var gridSpan45 = "Footer-module--grid-span-4-5--KHk66";
export var gridSpan46 = "Footer-module--grid-span-4-6--cVaKi";
export var gridSpan47 = "Footer-module--grid-span-4-7--eCdc+";
export var gridSpan48 = "Footer-module--grid-span-4-8--CUb4x";
export var gridSpan49 = "Footer-module--grid-span-4-9--Z0iRN";
export var gridSpan510 = "Footer-module--grid-span-5-10--G21Dg";
export var gridSpan511 = "Footer-module--grid-span-5-11--FIpWo";
export var gridSpan52 = "Footer-module--grid-span-5-2--aaT8p";
export var gridSpan53 = "Footer-module--grid-span-5-3---GzGN";
export var gridSpan54 = "Footer-module--grid-span-5-4--Ht33D";
export var gridSpan55 = "Footer-module--grid-span-5-5--zvLIu";
export var gridSpan56 = "Footer-module--grid-span-5-6--daKUL";
export var gridSpan57 = "Footer-module--grid-span-5-7--h56gC";
export var gridSpan58 = "Footer-module--grid-span-5-8--hc87U";
export var gridSpan59 = "Footer-module--grid-span-5-9--nMvFP";
export var gridSpan610 = "Footer-module--grid-span-6-10--qbuO3";
export var gridSpan611 = "Footer-module--grid-span-6-11--3oakA";
export var gridSpan62 = "Footer-module--grid-span-6-2--IxUP3";
export var gridSpan63 = "Footer-module--grid-span-6-3--Vip3n";
export var gridSpan64 = "Footer-module--grid-span-6-4--0KhCJ";
export var gridSpan65 = "Footer-module--grid-span-6-5---9EI5";
export var gridSpan66 = "Footer-module--grid-span-6-6--hL3Cb";
export var gridSpan67 = "Footer-module--grid-span-6-7--G0kXJ";
export var gridSpan68 = "Footer-module--grid-span-6-8--Ohvf0";
export var gridSpan69 = "Footer-module--grid-span-6-9--+9u-E";
export var gridSpan710 = "Footer-module--grid-span-7-10--HHqor";
export var gridSpan711 = "Footer-module--grid-span-7-11--qlziJ";
export var gridSpan72 = "Footer-module--grid-span-7-2--a4AgQ";
export var gridSpan73 = "Footer-module--grid-span-7-3--bfIYt";
export var gridSpan74 = "Footer-module--grid-span-7-4--DJVuv";
export var gridSpan75 = "Footer-module--grid-span-7-5--UKNpR";
export var gridSpan76 = "Footer-module--grid-span-7-6--G-aEa";
export var gridSpan77 = "Footer-module--grid-span-7-7--jnN96";
export var gridSpan78 = "Footer-module--grid-span-7-8--Fcmpr";
export var gridSpan79 = "Footer-module--grid-span-7-9--dZP9H";
export var gridSpan810 = "Footer-module--grid-span-8-10--qc8pZ";
export var gridSpan811 = "Footer-module--grid-span-8-11--RuW8X";
export var gridSpan82 = "Footer-module--grid-span-8-2--Ydoh5";
export var gridSpan83 = "Footer-module--grid-span-8-3--CaR2I";
export var gridSpan84 = "Footer-module--grid-span-8-4--tVct-";
export var gridSpan85 = "Footer-module--grid-span-8-5--T0kIb";
export var gridSpan86 = "Footer-module--grid-span-8-6--545s5";
export var gridSpan87 = "Footer-module--grid-span-8-7--oPAQ3";
export var gridSpan88 = "Footer-module--grid-span-8-8--hvrzQ";
export var gridSpan89 = "Footer-module--grid-span-8-9--l2If4";
export var gridSpan910 = "Footer-module--grid-span-9-10--xSkiK";
export var gridSpan911 = "Footer-module--grid-span-9-11--SAnPd";
export var gridSpan92 = "Footer-module--grid-span-9-2--WkDWT";
export var gridSpan93 = "Footer-module--grid-span-9-3--170XS";
export var gridSpan94 = "Footer-module--grid-span-9-4--8phYi";
export var gridSpan95 = "Footer-module--grid-span-9-5--V8+9H";
export var gridSpan96 = "Footer-module--grid-span-9-6--eE-7c";
export var gridSpan97 = "Footer-module--grid-span-9-7--CVmDb";
export var gridSpan98 = "Footer-module--grid-span-9-8--WSPlG";
export var gridSpan99 = "Footer-module--grid-span-9-9--npYOU";
export var textBreak = "Footer-module--text-break--dJLWt";
import React from "react";
import cx from "classnames";

import * as styles from "./Resources.module.scss";

import { RelatedPostCard } from "components/design-system";
import { RelatedPostCardProps } from "components/design-system/RelatedPostCard/RelatedPostCard";

interface ResourcesProps {
  title?: string;
  overflow?: boolean;
  description?: string;
  items?: RelatedPostCardProps[];
  align?: "left" | "center" | "right";
  titleAlign?: "left" | "center" | "right";
}

const Resources: React.FC<ResourcesProps> = ({
  title,
  align,
  items,
  overflow,
  titleAlign,
  description,
}) => {
  return (
    <section className={styles.Resources}>
      <div className={styles.Resources_container}>
        <header
          className={cx(
            styles.Resources_header,
            styles[`Resources_header_${titleAlign}`]
          )}
        >
          <h2 className={styles.Resources_header_title}>{title}</h2>
          <p
            className={styles.Resources_header_desc}
            dangerouslySetInnerHTML={{ __html: description as any }}
          ></p>
        </header>

        <div className={styles.Resources_main}>
          {items?.map((item, index) => (
            <RelatedPostCard
              {...item}
              key={index}
              align={align}
              overflow={overflow}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

Resources.defaultProps = {
  align: "left",
  overflow: true,
  titleAlign: "center",
};

export default Resources;

import { isArray } from "lodash";
import { handleError } from "./errorHandler";

export default async (
  promise: Promise<any>,
  errorHandler?: (e: any) => void
) => {
  const result: [any, any] = [null, null];
  try {
    const payload = await promise;
    if (isArray(payload)) result[0] = payload.map((item) => item.data);
    else result[0] = await payload.data;
  } catch (e: any) {
    result[1] = e;
    if (errorHandler) {
      errorHandler(e);
    } else {
      handleError(e);
    }
  }
  return result;
};

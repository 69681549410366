import React, { Component, createRef, ReactNode } from "react";
import cx from "classnames";
import { Button, ButtonVariant, notifyError } from "brass-ui-kit";
import { isEqual, isEmpty, pick } from "lodash";
import { captureException as SentryCaptureException } from "@sentry/react";
import UploadedFilesPanel from "./UploadedFilesPanel";

import * as styles from "./file-upload.module.scss";
import { IconFolder } from "assets/media/svgs";
import FileUploader from "helpers/utils/file-uploader";

interface FileUploadProps {
  fileID?: string;
  className?: string;
  multiple?: boolean;
  files?: any;
  reset?: boolean;
  accept?: string;
  uploadMessage?: string;
  setUploadedFileUrl: (url: string, file: any) => void;
  value?: string;
  disabled?: boolean;
  isPublic?: boolean;
  isAnon?: boolean;
  onUploadEnded?: () => void;
  onUploadStarted?: () => void;
  isButton?: boolean;
  customerId?: string;
  children?: ReactNode;
}

interface FileUploadState {
  files: any;
  uploadProgress: number;
  isUploading: boolean;
  highlight: boolean;
  fileUrl?: string;
}

export default class FileUpload extends Component<
  FileUploadProps,
  FileUploadState
> {
  static defaultProps = {
    setUploadedFileUrl: () => null,
    reset: false,
    isPublic: false,
    isAnon: false,
    accept: "image/*, .pdf",
  };

  fileUploadInputRef: React.RefObject<HTMLInputElement> = createRef();

  fileUploader = new FileUploader();

  state = {
    highlight: false,
    files: {},
    uploadProgress: 0,
    isUploading: false,
    fileUrl: "",
  };

  componentDidMount() {
    const { files } = this.props;
    if (files) {
      this.addFilesToState(files);
    }
  }

  componentDidUpdate(prevProps: any) {
    const { files } = this.props;
    if (!isEqual(files, prevProps.files)) {
      this.addFilesToState(files);
    }

    if (isEmpty(this.props.value) && this.props.value !== prevProps.value) {
      this.handleResetFileUpload();
    }

    if (!prevProps.reset && this.props.reset) {
      this.handleResetFileUpload();
    }
  }

  addFilesToState = (files: FileList) => {
    const { fileID, multiple } = this.props;
    const newFiles = { ...this.state.files };
    if (!multiple) {
      newFiles[0] = files[0];
    }
    this.setState({
      files: newFiles,
      uploadProgress: fileID ? 100 : 0,
      fileUrl: fileID || "",
    });
  };

  openFileDialog = () => {
    const { disabled } = this.props;
    if (disabled) return;
    this.fileUploadInputRef && this.fileUploadInputRef.current?.click();
  };

  onFilesAdded = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { disabled } = this.props;
    if (disabled) return;
    const { files } = e.target as HTMLFormElement;
    await this.addFilesToState(files);
    this.handleSumitFiles();
  };

  onDragOver = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (this.props.disabled) return;
    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = async (e: any) => {
    e.preventDefault();

    if (this.props.disabled) return;

    const { files } = e.dataTransfer;

    await this.addFilesToState(files);
    await this.setState({ highlight: false });
    this.handleSumitFiles();
  };

  handleSumitFiles = async () => {
    const { files, uploadProgress } = this.state;
    const {
      setUploadedFileUrl,
      isPublic,
      isAnon,
      onUploadEnded,
      onUploadStarted,
      customerId,
    } = this.props;
    if (Object.keys(files).length) {
      for (const id of Object.keys(files)) {
        try {
          this.setState({ isUploading: true });
          onUploadStarted && onUploadStarted();
          const fileUrl = await this.fileUploader.upload(
            id,
            files[id],
            uploadProgress,
            this.setFileUploadProgress,
            { isPublic: isPublic!, isAnon: isAnon!, customerId }
          );
          if (fileUrl) {
            this.setState({ isUploading: false, fileUrl });
            setUploadedFileUrl(fileUrl, Object.values(files));
            onUploadEnded && onUploadEnded();
          }
        } catch (e) {
          notifyError(
            "Something went wrong with file upload. Please refresh page and try again."
          );
          SentryCaptureException(e);
        }
      }
    }
  };

  handleResetFileUpload = () => {
    this.setState({ files: {} });
  };

  setFileUploadProgress = (percentage: number) => {
    const { files } = this.state;

    if (Object.keys(files).length) {
      percentage = percentage >= 100 ? 100 : percentage;
    } else {
      percentage = percentage >= 70 ? 70 : percentage;
    }
    this.setState({
      uploadProgress: percentage,
    });
  };

  render() {
    const {
      uploadMessage = "Drag and drop document here",
      fileID,
      isButton,
      children,
      className,
      ...rest
    } = this.props;
    const { highlight, files, uploadProgress, fileUrl, isUploading } =
      this.state;

    const inputProps = pick(rest, ["multiple", "disabled", "accept", "ref"]);

    if (isButton)
      return (
        <>
          <input
            ref={this.fileUploadInputRef}
            className={styles.singleFileUpload_dropzone_caption_fileInput}
            type="file"
            onChange={this.onFilesAdded}
            {...inputProps}
          />
          <Button
            className={className}
            onClick={this.openFileDialog}
            variant={ButtonVariant.Text}
          >
            {children}
          </Button>
        </>
      );

    return Object.keys({ ...files }).length ? (
      <UploadedFilesPanel
        fileID={fileID}
        files={files}
        onSubmit={this.handleSumitFiles}
        onReset={this.handleResetFileUpload}
        uploadProgress={uploadProgress}
        fileUrl={fileUrl}
        isUploading={isUploading}
        className={className}
      />
    ) : (
      <div
        className={cx(styles.singleFileUpload_dropzone, className, {
          [styles.singleFileUpload_dropzone_highlight]: highlight,
        })}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      >
        <IconFolder className={styles.singleFileUpload_dropzone_icon} />
        <div className={styles.singleFileUpload_dropzone_caption}>
          <input
            ref={this.fileUploadInputRef}
            className={styles.singleFileUpload_dropzone_caption_fileInput}
            type="file"
            onChange={this.onFilesAdded}
            {...inputProps}
          />
          <p className={styles.singleFileUpload_dropzone_caption_uploadMessage}>
            {uploadMessage} or{" "}
            <Button
              variant={ButtonVariant.Text}
              className={
                styles.singleFileUpload_dropzone_caption_uploadMessage_Link
              }
              onClick={this.openFileDialog}
            >
              Browse
            </Button>
          </p>
          <small
            className={styles.singleFileUpload_dropzone_caption_supportedFiles}
          >
            Supported file types: JPEG, PNG, PDF. Max file size: 2mb
          </small>
        </div>
      </div>
    );
  }
}

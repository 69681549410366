import React from "react";
import { DEFAULT_COUNTRY_ISO, NAVBAR_CONFIG } from "../config";
import { Link } from "gatsby";
import { NigeriaLogo } from "assets/media/svgs";
import { uniqueId } from "lodash";

import * as styles from "./CountrySwitcher.module.scss";

interface CountryList {
  name: string;
  isoCode: string;
  logo: JSX.Element;
  webUrl: any;
}

interface CountrySwitcherProps {
  isMobile?: boolean;
}

const CountrySwitcher: React.FC<CountrySwitcherProps> = ({ isMobile }) => {
  const [showCountryList, setShowCountryList] = React.useState(false);

  const renderCountryList = (data: CountryList) => {
    const isDefaultCountry = data.isoCode === DEFAULT_COUNTRY_ISO;
    const SubMenuComponent: any = isDefaultCountry ? Link : "a";
    let subMenuComponentProps = {
      key: uniqueId("nav-country"),
      className: isMobile
        ? styles.CountrySwitcher_mobile_subMenu_item
        : styles.CountrySwitcher_subMenu_item,
    };

    if (isDefaultCountry) {
      subMenuComponentProps["to"] = location.href;
    } else {
      subMenuComponentProps["href"] = data.webUrl;
      subMenuComponentProps["rel"] = "noopener noreferrer";
    }

    return (
      <SubMenuComponent {...subMenuComponentProps}>
        {data.logo}
        <span>{data.name}</span>
      </SubMenuComponent>
    );
  };

  const handleMouseEvent = React.useMemo(() => {
    let eventHandler: React.HTMLAttributes<HTMLDivElement> = {};
    if (isMobile) {
      eventHandler.onClick = () => setShowCountryList(!showCountryList);
    } else {
      eventHandler["onMouseEnter"] = () => {
        setShowCountryList(true);
      };
      eventHandler["onMouseLeave"] = () => {
        setShowCountryList(false);
      };
    }
    return eventHandler;
  }, [isMobile, showCountryList]);

  return (
    <div
      {...handleMouseEvent}
      className={
        isMobile ? styles.CountrySwitcher_mobile : styles.CountrySwitcher
      }
    >
      <div
        className={
          isMobile
            ? styles.CountrySwitcher_mobile_link
            : styles.CountrySwitcher_link
        }
      >
        {isMobile && <span>Country</span>}
        <NigeriaLogo />
      </div>

      {showCountryList && (
        <div
          className={
            isMobile
              ? styles.CountrySwitcher_mobile_subMenu
              : styles.CountrySwitcher_subMenu
          }
        >
          {NAVBAR_CONFIG.countrySwitcher.map((item) => renderCountryList(item))}
        </div>
      )}
    </div>
  );
};

export default CountrySwitcher;

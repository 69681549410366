import React from 'react';
import cx from 'classnames';
import { Card as UIKitCard } from 'brass-ui-kit';

import * as styles from './Card.module.scss';

const Card = ({ children, depth, customStyles }) => {
  return (
    <UIKitCard
      className={cx(styles.Card, customStyles, [
        depth === 0 && styles.Card_depth_0,
      ])}
    >
      {children}
    </UIKitCard>
  );
};

Card.defaultProps = {
  depth: 0,
};

export default Card;

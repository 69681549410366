
import amp from 'amplitude-js';
import { AMPLITUDE_API_KEY } from 'data/config';

class Amplitude {
  initialize = () => {
    amp.getInstance().init(AMPLITUDE_API_KEY);
  };

  identifyUser = (userId: string) => {
    amp.getInstance().setUserId(userId);
  };

  clearUser = (data: any, callback: () => void = () => {}) => {
    amp.getInstance().setUserId(null);
    callback();
  };

  log = (event: string, data: any, callback: () => void = () => {}) => {
    amp.getInstance().logEvent(event, data, callback);
    callback();
  };
}

export default new Amplitude();

// extracted by mini-css-extract-plugin
export var BlogSection = "BlogSection-module--BlogSection--MZwwr";
export var BlogSection_empty = "BlogSection-module--BlogSection_empty--2jIs4";
export var BlogSection_empty_text = "BlogSection-module--BlogSection_empty_text--SW4e-";
export var BlogSection_empty_title = "BlogSection-module--BlogSection_empty_title--NxYnG";
export var BlogSection_nav_item = "BlogSection-module--BlogSection_nav_item--icKHL";
export var BlogSection_nav_item_selected = "BlogSection-module--BlogSection_nav_item_selected--suC9C";
export var BlogSection_nav_laptop = "BlogSection-module--BlogSection_nav_laptop--MCuY3";
export var BlogSection_nav_laptop_container = "BlogSection-module--BlogSection_nav_laptop_container--q3Qae";
export var BlogSection_posts = "BlogSection-module--BlogSection_posts--c+zAq";
export var BlogSection_select_container = "BlogSection-module--BlogSection_select_container--m7ZSw";
export var container = "BlogSection-module--container--3z8pg";
export var gatsbyImageWrapperConstrained = "BlogSection-module--gatsby-image-wrapper-constrained--d9lyS";
export var gridSpan1010 = "BlogSection-module--grid-span-10-10--f3V0J";
export var gridSpan1011 = "BlogSection-module--grid-span-10-11--A1Pzz";
export var gridSpan102 = "BlogSection-module--grid-span-10-2--jUjRE";
export var gridSpan103 = "BlogSection-module--grid-span-10-3--Ogq5Z";
export var gridSpan104 = "BlogSection-module--grid-span-10-4--WZZJh";
export var gridSpan105 = "BlogSection-module--grid-span-10-5--80hdC";
export var gridSpan106 = "BlogSection-module--grid-span-10-6--YN54n";
export var gridSpan107 = "BlogSection-module--grid-span-10-7--fmcLs";
export var gridSpan108 = "BlogSection-module--grid-span-10-8--C600L";
export var gridSpan109 = "BlogSection-module--grid-span-10-9--fKwJz";
export var gridSpan110 = "BlogSection-module--grid-span-1-10--VgJtA";
export var gridSpan111 = "BlogSection-module--grid-span-1-11--kLa+7";
export var gridSpan1110 = "BlogSection-module--grid-span-11-10--HoZTH";
export var gridSpan1111 = "BlogSection-module--grid-span-11-11--HQXHJ";
export var gridSpan112 = "BlogSection-module--grid-span-11-2--5RYNK";
export var gridSpan113 = "BlogSection-module--grid-span-11-3--MtiWS";
export var gridSpan114 = "BlogSection-module--grid-span-11-4--WCL8W";
export var gridSpan115 = "BlogSection-module--grid-span-11-5--Ve4BT";
export var gridSpan116 = "BlogSection-module--grid-span-11-6--SHnWv";
export var gridSpan117 = "BlogSection-module--grid-span-11-7--PCnAW";
export var gridSpan118 = "BlogSection-module--grid-span-11-8--ZbPnO";
export var gridSpan119 = "BlogSection-module--grid-span-11-9--D-Q8+";
export var gridSpan12 = "BlogSection-module--grid-span-1-2--+TStZ";
export var gridSpan1210 = "BlogSection-module--grid-span-12-10--q0frt";
export var gridSpan1211 = "BlogSection-module--grid-span-12-11--3v+c5";
export var gridSpan122 = "BlogSection-module--grid-span-12-2--ndxRc";
export var gridSpan123 = "BlogSection-module--grid-span-12-3--kWzkD";
export var gridSpan124 = "BlogSection-module--grid-span-12-4--7fnPD";
export var gridSpan125 = "BlogSection-module--grid-span-12-5--FjASa";
export var gridSpan126 = "BlogSection-module--grid-span-12-6--BcaTO";
export var gridSpan127 = "BlogSection-module--grid-span-12-7--zHwJP";
export var gridSpan128 = "BlogSection-module--grid-span-12-8--O8O2G";
export var gridSpan129 = "BlogSection-module--grid-span-12-9--fKmM5";
export var gridSpan13 = "BlogSection-module--grid-span-1-3--dM4Zt";
export var gridSpan14 = "BlogSection-module--grid-span-1-4--TEbWt";
export var gridSpan15 = "BlogSection-module--grid-span-1-5--1UFB7";
export var gridSpan16 = "BlogSection-module--grid-span-1-6--4EGR-";
export var gridSpan17 = "BlogSection-module--grid-span-1-7--K34Ni";
export var gridSpan18 = "BlogSection-module--grid-span-1-8--uFWSh";
export var gridSpan19 = "BlogSection-module--grid-span-1-9--jDvjt";
export var gridSpan210 = "BlogSection-module--grid-span-2-10--QW7iI";
export var gridSpan211 = "BlogSection-module--grid-span-2-11--4wYQb";
export var gridSpan22 = "BlogSection-module--grid-span-2-2--gMbZ0";
export var gridSpan23 = "BlogSection-module--grid-span-2-3--faDsx";
export var gridSpan24 = "BlogSection-module--grid-span-2-4--XnILP";
export var gridSpan25 = "BlogSection-module--grid-span-2-5--jWZxl";
export var gridSpan26 = "BlogSection-module--grid-span-2-6--r0nLv";
export var gridSpan27 = "BlogSection-module--grid-span-2-7--bZUpj";
export var gridSpan28 = "BlogSection-module--grid-span-2-8--g2QLO";
export var gridSpan29 = "BlogSection-module--grid-span-2-9--pzQNR";
export var gridSpan310 = "BlogSection-module--grid-span-3-10--hFD0K";
export var gridSpan311 = "BlogSection-module--grid-span-3-11--xls+T";
export var gridSpan32 = "BlogSection-module--grid-span-3-2--jGsFy";
export var gridSpan33 = "BlogSection-module--grid-span-3-3--gbyQB";
export var gridSpan34 = "BlogSection-module--grid-span-3-4--tl4UW";
export var gridSpan35 = "BlogSection-module--grid-span-3-5--vx+jx";
export var gridSpan36 = "BlogSection-module--grid-span-3-6--USuRL";
export var gridSpan37 = "BlogSection-module--grid-span-3-7--TNNjp";
export var gridSpan38 = "BlogSection-module--grid-span-3-8--5LDIn";
export var gridSpan39 = "BlogSection-module--grid-span-3-9--Je5A1";
export var gridSpan410 = "BlogSection-module--grid-span-4-10--JwgUD";
export var gridSpan411 = "BlogSection-module--grid-span-4-11--rSAp4";
export var gridSpan42 = "BlogSection-module--grid-span-4-2--CohD6";
export var gridSpan43 = "BlogSection-module--grid-span-4-3--XFQRA";
export var gridSpan44 = "BlogSection-module--grid-span-4-4--aA7Lt";
export var gridSpan45 = "BlogSection-module--grid-span-4-5--lfwbN";
export var gridSpan46 = "BlogSection-module--grid-span-4-6--5GGau";
export var gridSpan47 = "BlogSection-module--grid-span-4-7--e5VhD";
export var gridSpan48 = "BlogSection-module--grid-span-4-8--u+Vb4";
export var gridSpan49 = "BlogSection-module--grid-span-4-9--OcOF4";
export var gridSpan510 = "BlogSection-module--grid-span-5-10--lmJY8";
export var gridSpan511 = "BlogSection-module--grid-span-5-11--kIBQp";
export var gridSpan52 = "BlogSection-module--grid-span-5-2--ED25Q";
export var gridSpan53 = "BlogSection-module--grid-span-5-3--jnG2j";
export var gridSpan54 = "BlogSection-module--grid-span-5-4--MxWCa";
export var gridSpan55 = "BlogSection-module--grid-span-5-5--torMw";
export var gridSpan56 = "BlogSection-module--grid-span-5-6--A3SVE";
export var gridSpan57 = "BlogSection-module--grid-span-5-7--Diuaw";
export var gridSpan58 = "BlogSection-module--grid-span-5-8--HGyQT";
export var gridSpan59 = "BlogSection-module--grid-span-5-9--aWwwN";
export var gridSpan610 = "BlogSection-module--grid-span-6-10--0uwN4";
export var gridSpan611 = "BlogSection-module--grid-span-6-11--cVAQq";
export var gridSpan62 = "BlogSection-module--grid-span-6-2--TCWbK";
export var gridSpan63 = "BlogSection-module--grid-span-6-3--cwMgZ";
export var gridSpan64 = "BlogSection-module--grid-span-6-4--HQvyt";
export var gridSpan65 = "BlogSection-module--grid-span-6-5--bJ4DS";
export var gridSpan66 = "BlogSection-module--grid-span-6-6--n1ukx";
export var gridSpan67 = "BlogSection-module--grid-span-6-7--cUCu6";
export var gridSpan68 = "BlogSection-module--grid-span-6-8--ds3iJ";
export var gridSpan69 = "BlogSection-module--grid-span-6-9--fWnf8";
export var gridSpan710 = "BlogSection-module--grid-span-7-10--n5KKt";
export var gridSpan711 = "BlogSection-module--grid-span-7-11--6oOu1";
export var gridSpan72 = "BlogSection-module--grid-span-7-2--o1E9C";
export var gridSpan73 = "BlogSection-module--grid-span-7-3--a8bYA";
export var gridSpan74 = "BlogSection-module--grid-span-7-4--QWZQX";
export var gridSpan75 = "BlogSection-module--grid-span-7-5--b3tot";
export var gridSpan76 = "BlogSection-module--grid-span-7-6--12X8o";
export var gridSpan77 = "BlogSection-module--grid-span-7-7--L+B0b";
export var gridSpan78 = "BlogSection-module--grid-span-7-8--UWHb6";
export var gridSpan79 = "BlogSection-module--grid-span-7-9--xbPlm";
export var gridSpan810 = "BlogSection-module--grid-span-8-10--2cxfR";
export var gridSpan811 = "BlogSection-module--grid-span-8-11--0cWZ7";
export var gridSpan82 = "BlogSection-module--grid-span-8-2--JO7wp";
export var gridSpan83 = "BlogSection-module--grid-span-8-3--pMXoM";
export var gridSpan84 = "BlogSection-module--grid-span-8-4--ZbVHC";
export var gridSpan85 = "BlogSection-module--grid-span-8-5--26xpC";
export var gridSpan86 = "BlogSection-module--grid-span-8-6--TNp1g";
export var gridSpan87 = "BlogSection-module--grid-span-8-7--qrs89";
export var gridSpan88 = "BlogSection-module--grid-span-8-8--z7qC-";
export var gridSpan89 = "BlogSection-module--grid-span-8-9--CS14V";
export var gridSpan910 = "BlogSection-module--grid-span-9-10--5n74U";
export var gridSpan911 = "BlogSection-module--grid-span-9-11--chuXp";
export var gridSpan92 = "BlogSection-module--grid-span-9-2--zLkT2";
export var gridSpan93 = "BlogSection-module--grid-span-9-3--yz3TL";
export var gridSpan94 = "BlogSection-module--grid-span-9-4--wcYul";
export var gridSpan95 = "BlogSection-module--grid-span-9-5--hDk-J";
export var gridSpan96 = "BlogSection-module--grid-span-9-6--oYqNd";
export var gridSpan97 = "BlogSection-module--grid-span-9-7--wHasA";
export var gridSpan98 = "BlogSection-module--grid-span-9-8--pHiO-";
export var gridSpan99 = "BlogSection-module--grid-span-9-9--N-j4h";
export var textBreak = "BlogSection-module--text-break--kQzdo";
import React from "react";
import { Button, ButtonVariant, Modal } from "brass-ui-kit";
import { LayoutContext } from "context";
import { DEFAULT_APP_DOWNLOAD_DATA, appDownloadData } from "../config";
import {
  AndroidIcon,
  BackArrowIcon,
  IosIcon,
} from "components/design-system/Modals/assets";

import * as styles from "./AppDownloadModal.module.scss";
import { AppDownloadType } from "types";

const AppDownloadModal: React.FC = () => {
  const { openAppDownloadModal, appDownloadModalType, updateContext } =
    React.useContext(LayoutContext);

  const downloadData = !!appDownloadModalType
    ? appDownloadData[appDownloadModalType]
    : DEFAULT_APP_DOWNLOAD_DATA;

  const handleClose = () =>
    updateContext({
      openAppDownloadModal: false,
      appDownloadModalType: null,
    });

  const { scheme_url, store_url } = downloadData;

  const handleAppRedirect = (schemeUrl: string, storeUrl: string) => {
    if (appDownloadModalType === AppDownloadType.BusinessApp) {
      window.open(schemeUrl);
      setTimeout(() => window.open(storeUrl, "_blank"), 300);
    }
    window.open(storeUrl, "_blank");
  };

  return (
    <Modal
      isOpen={openAppDownloadModal}
      className={styles.AppDownloadModal}
      closeModal={handleClose}
    >
      <div className={styles.AppDownloadModal_container}>
        <Button
          variant={ButtonVariant.Text}
          className={styles.AppDownloadModal_btn}
          onClick={handleClose}
        >
          <BackArrowIcon />
          Back to website
        </Button>

        <div className={styles.AppDownloadModal_content}>
          <img
            src={downloadData.img}
            alt={downloadData.text}
            className={styles.AppDownloadModal_content_img}
          />

          <div className={styles.AppDownloadModal_content_info}>
            <h3 className={styles.AppDownloadModal_content_info_title}>
              {downloadData.title}
            </h3>

            <p className={styles.AppDownloadModal_content_info_text}>
              {downloadData.text}
            </p>
          </div>

          <div className={styles.AppDownloadModal_content_btns}>
            <Button
              variant={ButtonVariant.Black}
              onClick={() =>
                handleAppRedirect(scheme_url.google, store_url.google)
              }
              className={styles.AppDownloadModal_content_btns_btn}
            >
              <AndroidIcon />
              Android
            </Button>

            <Button
              variant={ButtonVariant.Black}
              className={styles.AppDownloadModal_content_btns_btn}
              onClick={() => handleAppRedirect(scheme_url.ios, store_url.ios)}
            >
              <IosIcon />
              iOS
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppDownloadModal;

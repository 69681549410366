import React from "react";
import cx from "classnames";
import { ProgressBar } from "brass-ui-kit";
import * as styles from "./file-upload.module.scss";
import { IconCheckGreen, IconClose } from "assets/media/svgs";
import { Spinner } from "..";

interface UploadedFilesPanelProps {
  className?: string;
  fileID?: string;
  files: any;
  onReset: (id?: string) => void;
  isUploading: boolean;
  fileUrl: string;
  onSubmit: () => Promise<void>;
  uploadProgress: number;
}

const UploadedFilesPanel: React.FC<UploadedFilesPanelProps> = ({
  className,
  fileID,
  files,
  onReset,
  uploadProgress,
  isUploading,
  fileUrl,
}) => {
  const isUploadComplete = !isUploading && fileUrl;
  return (
    <section className={cx(styles.uploadedFilesPanel, className)}>
      {files &&
        Object.keys(files).map((id) => (
          <div
            className={styles.uploadedFilesPanel_file}
            key={`${id}-${files[id]?.name}`}
          >
            <div className={styles.uploadedFilesPanel_file_caption}>
              <div className={styles.uploadedFilesPanel_file_caption_ttl}>
                {isUploading ? (
                  <Spinner height={20} width={20} />
                ) : (
                  fileUrl && <IconCheckGreen />
                )}
                <h4 className={styles.uploadedFilesPanel_file_caption_ttl_text}>
                  {files[id]?.name}
                </h4>
              </div>
              <IconClose
                className={styles.uploadedFilesPanel_file_caption_close}
                onClick={() => {
                  onReset(fileID);
                }}
              />
            </div>
            {!isUploadComplete && (
              <ProgressBar
                className={styles.uploadedFilesPanel_file_progressbar}
                value={uploadProgress}
                total={100}
              />
            )}
          </div>
        ))}
    </section>
  );
};

export default UploadedFilesPanel;

// extracted by mini-css-extract-plugin
export var Featured_container = "Featured-module--Featured_container--A3ITg";
export var Featured_content = "Featured-module--Featured_content--seuEt";
export var Featured_description = "Featured-module--Featured_description--pc-p6";
export var Featured_image = "Featured-module--Featured_image--R+1qK";
export var Featured_link = "Featured-module--Featured_link--U1nhb";
export var Featured_title = "Featured-module--Featured_title--ZHFLX";
export var Featured_type = "Featured-module--Featured_type--dKcyI";
export var container = "Featured-module--container--pxZYM";
export var gatsbyImageWrapperConstrained = "Featured-module--gatsby-image-wrapper-constrained--JTkcU";
export var gridSpan1010 = "Featured-module--grid-span-10-10--hVqhU";
export var gridSpan1011 = "Featured-module--grid-span-10-11--3pNn9";
export var gridSpan102 = "Featured-module--grid-span-10-2--a00pw";
export var gridSpan103 = "Featured-module--grid-span-10-3--Rn2jf";
export var gridSpan104 = "Featured-module--grid-span-10-4--SB9nc";
export var gridSpan105 = "Featured-module--grid-span-10-5--VIIoJ";
export var gridSpan106 = "Featured-module--grid-span-10-6--s9LWW";
export var gridSpan107 = "Featured-module--grid-span-10-7--kS8oq";
export var gridSpan108 = "Featured-module--grid-span-10-8--G3JyX";
export var gridSpan109 = "Featured-module--grid-span-10-9--pEa4X";
export var gridSpan110 = "Featured-module--grid-span-1-10--56svW";
export var gridSpan111 = "Featured-module--grid-span-1-11--OBCG1";
export var gridSpan1110 = "Featured-module--grid-span-11-10--tS9Zb";
export var gridSpan1111 = "Featured-module--grid-span-11-11--wRtQj";
export var gridSpan112 = "Featured-module--grid-span-11-2--aoPWG";
export var gridSpan113 = "Featured-module--grid-span-11-3--iQaDf";
export var gridSpan114 = "Featured-module--grid-span-11-4--Z3m+t";
export var gridSpan115 = "Featured-module--grid-span-11-5--kFcQB";
export var gridSpan116 = "Featured-module--grid-span-11-6--wDw--";
export var gridSpan117 = "Featured-module--grid-span-11-7--vc8Wa";
export var gridSpan118 = "Featured-module--grid-span-11-8--IEqz-";
export var gridSpan119 = "Featured-module--grid-span-11-9--1HYUV";
export var gridSpan12 = "Featured-module--grid-span-1-2--d+Dk2";
export var gridSpan1210 = "Featured-module--grid-span-12-10--pNS8C";
export var gridSpan1211 = "Featured-module--grid-span-12-11--A2inn";
export var gridSpan122 = "Featured-module--grid-span-12-2--nHjYR";
export var gridSpan123 = "Featured-module--grid-span-12-3--Ie8QP";
export var gridSpan124 = "Featured-module--grid-span-12-4--s8Fo5";
export var gridSpan125 = "Featured-module--grid-span-12-5--jzJNx";
export var gridSpan126 = "Featured-module--grid-span-12-6--9qneZ";
export var gridSpan127 = "Featured-module--grid-span-12-7--6VWKw";
export var gridSpan128 = "Featured-module--grid-span-12-8--y4nXd";
export var gridSpan129 = "Featured-module--grid-span-12-9--nU9IR";
export var gridSpan13 = "Featured-module--grid-span-1-3--CDaET";
export var gridSpan14 = "Featured-module--grid-span-1-4--riZU7";
export var gridSpan15 = "Featured-module--grid-span-1-5--LfyHm";
export var gridSpan16 = "Featured-module--grid-span-1-6--rO6EZ";
export var gridSpan17 = "Featured-module--grid-span-1-7--WSw2I";
export var gridSpan18 = "Featured-module--grid-span-1-8--QCkU3";
export var gridSpan19 = "Featured-module--grid-span-1-9--Uox+O";
export var gridSpan210 = "Featured-module--grid-span-2-10--ZnuWS";
export var gridSpan211 = "Featured-module--grid-span-2-11--Wo4AD";
export var gridSpan22 = "Featured-module--grid-span-2-2--FNjBg";
export var gridSpan23 = "Featured-module--grid-span-2-3--RkyUd";
export var gridSpan24 = "Featured-module--grid-span-2-4--iZlOu";
export var gridSpan25 = "Featured-module--grid-span-2-5--hAXus";
export var gridSpan26 = "Featured-module--grid-span-2-6--BEXw2";
export var gridSpan27 = "Featured-module--grid-span-2-7--e4b2F";
export var gridSpan28 = "Featured-module--grid-span-2-8--2Z-us";
export var gridSpan29 = "Featured-module--grid-span-2-9--yBits";
export var gridSpan310 = "Featured-module--grid-span-3-10--3v8f6";
export var gridSpan311 = "Featured-module--grid-span-3-11--5EdRx";
export var gridSpan32 = "Featured-module--grid-span-3-2--v3+vu";
export var gridSpan33 = "Featured-module--grid-span-3-3--XQuv7";
export var gridSpan34 = "Featured-module--grid-span-3-4--8KFpg";
export var gridSpan35 = "Featured-module--grid-span-3-5--OBtca";
export var gridSpan36 = "Featured-module--grid-span-3-6--JhWhw";
export var gridSpan37 = "Featured-module--grid-span-3-7--offlU";
export var gridSpan38 = "Featured-module--grid-span-3-8--S-Q+B";
export var gridSpan39 = "Featured-module--grid-span-3-9--iELZm";
export var gridSpan410 = "Featured-module--grid-span-4-10--nQOLn";
export var gridSpan411 = "Featured-module--grid-span-4-11--udz2A";
export var gridSpan42 = "Featured-module--grid-span-4-2--ArHEz";
export var gridSpan43 = "Featured-module--grid-span-4-3--FfPMv";
export var gridSpan44 = "Featured-module--grid-span-4-4--LYyxN";
export var gridSpan45 = "Featured-module--grid-span-4-5--+hBly";
export var gridSpan46 = "Featured-module--grid-span-4-6--PEeZ-";
export var gridSpan47 = "Featured-module--grid-span-4-7--C8BlU";
export var gridSpan48 = "Featured-module--grid-span-4-8--tR7Bj";
export var gridSpan49 = "Featured-module--grid-span-4-9--OehgE";
export var gridSpan510 = "Featured-module--grid-span-5-10--6WsUI";
export var gridSpan511 = "Featured-module--grid-span-5-11--IIm1W";
export var gridSpan52 = "Featured-module--grid-span-5-2--ihAHz";
export var gridSpan53 = "Featured-module--grid-span-5-3--XRxvc";
export var gridSpan54 = "Featured-module--grid-span-5-4--r3Fzc";
export var gridSpan55 = "Featured-module--grid-span-5-5--jtZBE";
export var gridSpan56 = "Featured-module--grid-span-5-6---utIa";
export var gridSpan57 = "Featured-module--grid-span-5-7--P1-Bp";
export var gridSpan58 = "Featured-module--grid-span-5-8--u80HK";
export var gridSpan59 = "Featured-module--grid-span-5-9--1ZdzG";
export var gridSpan610 = "Featured-module--grid-span-6-10--Bj+Vq";
export var gridSpan611 = "Featured-module--grid-span-6-11--rTcJv";
export var gridSpan62 = "Featured-module--grid-span-6-2--xuRI7";
export var gridSpan63 = "Featured-module--grid-span-6-3--p8L1D";
export var gridSpan64 = "Featured-module--grid-span-6-4--fVdBF";
export var gridSpan65 = "Featured-module--grid-span-6-5--cJrXT";
export var gridSpan66 = "Featured-module--grid-span-6-6--Myool";
export var gridSpan67 = "Featured-module--grid-span-6-7--mM-jc";
export var gridSpan68 = "Featured-module--grid-span-6-8--vXFla";
export var gridSpan69 = "Featured-module--grid-span-6-9--Lp75i";
export var gridSpan710 = "Featured-module--grid-span-7-10--CG6hz";
export var gridSpan711 = "Featured-module--grid-span-7-11--aYch5";
export var gridSpan72 = "Featured-module--grid-span-7-2--A1BJQ";
export var gridSpan73 = "Featured-module--grid-span-7-3--zyvDR";
export var gridSpan74 = "Featured-module--grid-span-7-4--HPMJn";
export var gridSpan75 = "Featured-module--grid-span-7-5--k+Rl8";
export var gridSpan76 = "Featured-module--grid-span-7-6--ZQFVK";
export var gridSpan77 = "Featured-module--grid-span-7-7--PyCYB";
export var gridSpan78 = "Featured-module--grid-span-7-8--XZ9QN";
export var gridSpan79 = "Featured-module--grid-span-7-9--r9vXQ";
export var gridSpan810 = "Featured-module--grid-span-8-10--WWlxb";
export var gridSpan811 = "Featured-module--grid-span-8-11--C+Pl1";
export var gridSpan82 = "Featured-module--grid-span-8-2--NguOx";
export var gridSpan83 = "Featured-module--grid-span-8-3--O72Ly";
export var gridSpan84 = "Featured-module--grid-span-8-4--0FPLF";
export var gridSpan85 = "Featured-module--grid-span-8-5--2ooc0";
export var gridSpan86 = "Featured-module--grid-span-8-6--mfoh7";
export var gridSpan87 = "Featured-module--grid-span-8-7--FN6mN";
export var gridSpan88 = "Featured-module--grid-span-8-8--WArV+";
export var gridSpan89 = "Featured-module--grid-span-8-9--WZerU";
export var gridSpan910 = "Featured-module--grid-span-9-10--6+T1g";
export var gridSpan911 = "Featured-module--grid-span-9-11--PWAGG";
export var gridSpan92 = "Featured-module--grid-span-9-2--2ATYh";
export var gridSpan93 = "Featured-module--grid-span-9-3--Uzyhz";
export var gridSpan94 = "Featured-module--grid-span-9-4--FXt3a";
export var gridSpan95 = "Featured-module--grid-span-9-5--CYWHP";
export var gridSpan96 = "Featured-module--grid-span-9-6--OXSlL";
export var gridSpan97 = "Featured-module--grid-span-9-7--8-ejT";
export var gridSpan98 = "Featured-module--grid-span-9-8--FfCuS";
export var gridSpan99 = "Featured-module--grid-span-9-9--MLTnS";
export var textBreak = "Featured-module--text-break--BD44W";
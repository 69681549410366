import React from "react";
import cx from "classnames";

import * as styles from "./QualitiesList.module.scss";

interface QualitiesType {
  title: string;
  image?: string;
  description: string;
}

interface QualitiesProps {
  className?: string;
  items: QualitiesType[];
  theme?: "dark" | "light";
  isEmbed?: boolean;
}

const QualitiesList: React.FC<QualitiesProps> = ({
  items,
  theme,
  className,
  isEmbed,
}) => {
  return (
    <div
      className={cx(
        {
          [styles.QualitiesList_theme_dark]: theme === "dark",
        },
        className
      )}
    >
      <div
        className={cx({
          [styles.QualitiesList_container]: !isEmbed,
        })}
      >
        <div className={styles.QualitiesList}>
          {items.map((quality, index) => (
            <div key={index} className={styles.QualitiesList_item}>
              <div className={styles.QualitiesList_item_image}>
                <img src={quality.image} alt="" />
              </div>
              <div className={styles.QualitiesList_item_text}>
                <h4 className={styles.QualitiesList_item_title}>
                  {quality.title}
                </h4>
                <p className={styles.QualitiesList_item_desc}>
                  {quality.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

QualitiesList.defaultProps = {
  theme: "light",
  className: "",
  isEmbed: false,
};

export default QualitiesList;

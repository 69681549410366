// extracted by mini-css-extract-plugin
export var RelatedPostCard = "RelatedPostCard-module--RelatedPostCard---rgS+";
export var RelatedPostCard_content = "RelatedPostCard-module--RelatedPostCard_content--NQ2uh";
export var RelatedPostCard_content_excerpt = "RelatedPostCard-module--RelatedPostCard_content_excerpt--EXYe1";
export var RelatedPostCard_content_overflow = "RelatedPostCard-module--RelatedPostCard_content_overflow--R1p0X";
export var RelatedPostCard_content_text_center = "RelatedPostCard-module--RelatedPostCard_content_text_center--76cbt";
export var RelatedPostCard_content_text_left = "RelatedPostCard-module--RelatedPostCard_content_text_left--1L4TB";
export var RelatedPostCard_content_text_right = "RelatedPostCard-module--RelatedPostCard_content_text_right--10SgL";
export var RelatedPostCard_content_title = "RelatedPostCard-module--RelatedPostCard_content_title--yeXR9";
export var RelatedPostCard_image = "RelatedPostCard-module--RelatedPostCard_image--DqERc";
export var RelatedPostCard_image_img = "RelatedPostCard-module--RelatedPostCard_image_img--gqioR";
export var container = "RelatedPostCard-module--container--xCgLS";
export var gatsbyImageWrapperConstrained = "RelatedPostCard-module--gatsby-image-wrapper-constrained--VgiFS";
export var gridSpan1010 = "RelatedPostCard-module--grid-span-10-10--ah3-N";
export var gridSpan1011 = "RelatedPostCard-module--grid-span-10-11--ckt4A";
export var gridSpan102 = "RelatedPostCard-module--grid-span-10-2--ngG4w";
export var gridSpan103 = "RelatedPostCard-module--grid-span-10-3--Qbm3h";
export var gridSpan104 = "RelatedPostCard-module--grid-span-10-4--N654P";
export var gridSpan105 = "RelatedPostCard-module--grid-span-10-5--TTZZH";
export var gridSpan106 = "RelatedPostCard-module--grid-span-10-6--KMeqI";
export var gridSpan107 = "RelatedPostCard-module--grid-span-10-7--cEKk7";
export var gridSpan108 = "RelatedPostCard-module--grid-span-10-8--yYrcU";
export var gridSpan109 = "RelatedPostCard-module--grid-span-10-9--Hl3Gu";
export var gridSpan110 = "RelatedPostCard-module--grid-span-1-10--MrQGq";
export var gridSpan111 = "RelatedPostCard-module--grid-span-1-11--r7xk5";
export var gridSpan1110 = "RelatedPostCard-module--grid-span-11-10--PD1Cv";
export var gridSpan1111 = "RelatedPostCard-module--grid-span-11-11--qCb2Q";
export var gridSpan112 = "RelatedPostCard-module--grid-span-11-2--CKDhr";
export var gridSpan113 = "RelatedPostCard-module--grid-span-11-3--+B2j1";
export var gridSpan114 = "RelatedPostCard-module--grid-span-11-4--T1gM7";
export var gridSpan115 = "RelatedPostCard-module--grid-span-11-5--7IrGE";
export var gridSpan116 = "RelatedPostCard-module--grid-span-11-6--nNpvf";
export var gridSpan117 = "RelatedPostCard-module--grid-span-11-7--X2i9g";
export var gridSpan118 = "RelatedPostCard-module--grid-span-11-8--rawrf";
export var gridSpan119 = "RelatedPostCard-module--grid-span-11-9--ZQG6W";
export var gridSpan12 = "RelatedPostCard-module--grid-span-1-2--Q4asE";
export var gridSpan1210 = "RelatedPostCard-module--grid-span-12-10--jSBMg";
export var gridSpan1211 = "RelatedPostCard-module--grid-span-12-11--fSTxp";
export var gridSpan122 = "RelatedPostCard-module--grid-span-12-2--llPSK";
export var gridSpan123 = "RelatedPostCard-module--grid-span-12-3--kFfau";
export var gridSpan124 = "RelatedPostCard-module--grid-span-12-4--xvEEI";
export var gridSpan125 = "RelatedPostCard-module--grid-span-12-5--aqIIJ";
export var gridSpan126 = "RelatedPostCard-module--grid-span-12-6--pyuiT";
export var gridSpan127 = "RelatedPostCard-module--grid-span-12-7--3HygX";
export var gridSpan128 = "RelatedPostCard-module--grid-span-12-8--9pYHs";
export var gridSpan129 = "RelatedPostCard-module--grid-span-12-9--KI7pd";
export var gridSpan13 = "RelatedPostCard-module--grid-span-1-3--ExWdo";
export var gridSpan14 = "RelatedPostCard-module--grid-span-1-4--uDtcI";
export var gridSpan15 = "RelatedPostCard-module--grid-span-1-5--PvwjA";
export var gridSpan16 = "RelatedPostCard-module--grid-span-1-6--s6wQc";
export var gridSpan17 = "RelatedPostCard-module--grid-span-1-7--zS1hM";
export var gridSpan18 = "RelatedPostCard-module--grid-span-1-8--jowPH";
export var gridSpan19 = "RelatedPostCard-module--grid-span-1-9--PIrrk";
export var gridSpan210 = "RelatedPostCard-module--grid-span-2-10--4j7Zi";
export var gridSpan211 = "RelatedPostCard-module--grid-span-2-11--WR+m7";
export var gridSpan22 = "RelatedPostCard-module--grid-span-2-2--OR8L3";
export var gridSpan23 = "RelatedPostCard-module--grid-span-2-3--BbGjK";
export var gridSpan24 = "RelatedPostCard-module--grid-span-2-4--hYSSq";
export var gridSpan25 = "RelatedPostCard-module--grid-span-2-5--X1mLi";
export var gridSpan26 = "RelatedPostCard-module--grid-span-2-6--uEnfO";
export var gridSpan27 = "RelatedPostCard-module--grid-span-2-7--vbsoa";
export var gridSpan28 = "RelatedPostCard-module--grid-span-2-8--T+AKs";
export var gridSpan29 = "RelatedPostCard-module--grid-span-2-9--a5nCS";
export var gridSpan310 = "RelatedPostCard-module--grid-span-3-10--k5Ul2";
export var gridSpan311 = "RelatedPostCard-module--grid-span-3-11--6v6PM";
export var gridSpan32 = "RelatedPostCard-module--grid-span-3-2--Y2b92";
export var gridSpan33 = "RelatedPostCard-module--grid-span-3-3--7wQsl";
export var gridSpan34 = "RelatedPostCard-module--grid-span-3-4--eSPLq";
export var gridSpan35 = "RelatedPostCard-module--grid-span-3-5--RgFOv";
export var gridSpan36 = "RelatedPostCard-module--grid-span-3-6--jZtPk";
export var gridSpan37 = "RelatedPostCard-module--grid-span-3-7--Yih2D";
export var gridSpan38 = "RelatedPostCard-module--grid-span-3-8--EQ2qp";
export var gridSpan39 = "RelatedPostCard-module--grid-span-3-9--Gc1mm";
export var gridSpan410 = "RelatedPostCard-module--grid-span-4-10--iDtG8";
export var gridSpan411 = "RelatedPostCard-module--grid-span-4-11---aHhv";
export var gridSpan42 = "RelatedPostCard-module--grid-span-4-2--1Aw4F";
export var gridSpan43 = "RelatedPostCard-module--grid-span-4-3--YR+MQ";
export var gridSpan44 = "RelatedPostCard-module--grid-span-4-4--4fcAk";
export var gridSpan45 = "RelatedPostCard-module--grid-span-4-5--0vC8J";
export var gridSpan46 = "RelatedPostCard-module--grid-span-4-6--Cpn3s";
export var gridSpan47 = "RelatedPostCard-module--grid-span-4-7--EP3vJ";
export var gridSpan48 = "RelatedPostCard-module--grid-span-4-8--4FzQM";
export var gridSpan49 = "RelatedPostCard-module--grid-span-4-9--IHwnY";
export var gridSpan510 = "RelatedPostCard-module--grid-span-5-10--LCnfS";
export var gridSpan511 = "RelatedPostCard-module--grid-span-5-11--AUxgf";
export var gridSpan52 = "RelatedPostCard-module--grid-span-5-2--VgOXN";
export var gridSpan53 = "RelatedPostCard-module--grid-span-5-3--jBngb";
export var gridSpan54 = "RelatedPostCard-module--grid-span-5-4--3OYMU";
export var gridSpan55 = "RelatedPostCard-module--grid-span-5-5--kb9nG";
export var gridSpan56 = "RelatedPostCard-module--grid-span-5-6--i2Fyh";
export var gridSpan57 = "RelatedPostCard-module--grid-span-5-7--IFnSL";
export var gridSpan58 = "RelatedPostCard-module--grid-span-5-8--TEORq";
export var gridSpan59 = "RelatedPostCard-module--grid-span-5-9--cz1jt";
export var gridSpan610 = "RelatedPostCard-module--grid-span-6-10--IHQk8";
export var gridSpan611 = "RelatedPostCard-module--grid-span-6-11--zNG-s";
export var gridSpan62 = "RelatedPostCard-module--grid-span-6-2--WOQZB";
export var gridSpan63 = "RelatedPostCard-module--grid-span-6-3--02e01";
export var gridSpan64 = "RelatedPostCard-module--grid-span-6-4--EIKrk";
export var gridSpan65 = "RelatedPostCard-module--grid-span-6-5--QEX6V";
export var gridSpan66 = "RelatedPostCard-module--grid-span-6-6--Lc+IC";
export var gridSpan67 = "RelatedPostCard-module--grid-span-6-7--paRfx";
export var gridSpan68 = "RelatedPostCard-module--grid-span-6-8--WhAth";
export var gridSpan69 = "RelatedPostCard-module--grid-span-6-9--O7vwI";
export var gridSpan710 = "RelatedPostCard-module--grid-span-7-10--QZMBV";
export var gridSpan711 = "RelatedPostCard-module--grid-span-7-11--UBlN2";
export var gridSpan72 = "RelatedPostCard-module--grid-span-7-2--lgCw4";
export var gridSpan73 = "RelatedPostCard-module--grid-span-7-3--DREBM";
export var gridSpan74 = "RelatedPostCard-module--grid-span-7-4--UqJmi";
export var gridSpan75 = "RelatedPostCard-module--grid-span-7-5--tU7Aq";
export var gridSpan76 = "RelatedPostCard-module--grid-span-7-6--sVZ-g";
export var gridSpan77 = "RelatedPostCard-module--grid-span-7-7--wMulo";
export var gridSpan78 = "RelatedPostCard-module--grid-span-7-8---1Dxp";
export var gridSpan79 = "RelatedPostCard-module--grid-span-7-9--1OIzn";
export var gridSpan810 = "RelatedPostCard-module--grid-span-8-10--URut6";
export var gridSpan811 = "RelatedPostCard-module--grid-span-8-11--L-e8e";
export var gridSpan82 = "RelatedPostCard-module--grid-span-8-2--URANh";
export var gridSpan83 = "RelatedPostCard-module--grid-span-8-3--lNEY7";
export var gridSpan84 = "RelatedPostCard-module--grid-span-8-4--R-tAf";
export var gridSpan85 = "RelatedPostCard-module--grid-span-8-5--MMk2R";
export var gridSpan86 = "RelatedPostCard-module--grid-span-8-6--e-SnD";
export var gridSpan87 = "RelatedPostCard-module--grid-span-8-7--UzwaX";
export var gridSpan88 = "RelatedPostCard-module--grid-span-8-8--3Mh1w";
export var gridSpan89 = "RelatedPostCard-module--grid-span-8-9--iqpNI";
export var gridSpan910 = "RelatedPostCard-module--grid-span-9-10--dwPFN";
export var gridSpan911 = "RelatedPostCard-module--grid-span-9-11--KxtLA";
export var gridSpan92 = "RelatedPostCard-module--grid-span-9-2--Kvj2g";
export var gridSpan93 = "RelatedPostCard-module--grid-span-9-3--kfEGC";
export var gridSpan94 = "RelatedPostCard-module--grid-span-9-4--biswr";
export var gridSpan95 = "RelatedPostCard-module--grid-span-9-5--fmGjj";
export var gridSpan96 = "RelatedPostCard-module--grid-span-9-6--CsaUf";
export var gridSpan97 = "RelatedPostCard-module--grid-span-9-7--QmV28";
export var gridSpan98 = "RelatedPostCard-module--grid-span-9-8--Gl0zJ";
export var gridSpan99 = "RelatedPostCard-module--grid-span-9-9--oo539";
export var textBreak = "RelatedPostCard-module--text-break--ZY+s8";
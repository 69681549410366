import React from "react";
import { AppDownloadType } from "types";

import BusinessImg from "../assets/images/business-img.png";
import StarterImg from "../assets/images/starter-img.png";
import { REDIRECT_URL } from "./generics";

export const DEFAULT_APP_DOWNLOAD_DATA = {
  img: "",
  title: "",
  text: "",
  store_url: {
    google: "https://www.google.com/",
    ios: "https://www.apple.com/",
  },
  scheme_url: {
    google: "",
    ios: "",
  },
};

export const appDownloadData = {
  [AppDownloadType.BusinessApp]: {
    img: BusinessImg,
    title: "Download the app to get started",
    text: "To open a business account for your registered business, download the Brass mobile app.",
    ...REDIRECT_URL.business,
  },
  [AppDownloadType.StarterApp]: {
    img: StarterImg,
    title: "Download the app to get started",
    text: "To open a business account for your registered business, download the Brass starter mobile app.",
    ...REDIRECT_URL.starter,
  },
};

import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Card } from "components/design-system";

import * as styles from "./RelatedPostCard.module.scss";
import markdownToHtml from "helpers/utils/markdownLoader";

export interface RelatedPostCardProps {
  image?: any;
  title: string;
  link?: string;
  excerpt: string;
  overflow?: boolean;
  featuredImage?: string;
  align?: "left" | "center" | "right";
}

const Wrapper = ({ children, link }: any) => {
  if (!link) {
    return <div>{children}</div>;
  }
  const isInternal = /^\/(?!\/)/.test(link);

  return isInternal ? (
    <Link to={link}>{children}</Link>
  ) : (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const RelatedPostCard: React.FC<RelatedPostCardProps> = ({
  link,
  image,
  title,
  align,
  excerpt,
  overflow,
  featuredImage,
}) => {
  const images =
    !featuredImage && getImage(image.childImageSharp.gatsbyImageData);

  return (
    <Wrapper link={link}>
      <Card customStyles={styles.RelatedPostCard}>
        <div className={styles.RelatedPostCard_image}>
          {featuredImage && (
            <img
              alt={title}
              src={featuredImage}
              className={styles.RelatedPostCard_image_img}
            />
          )}
          {images && (
            <GatsbyImage
              as="span"
              alt={title}
              image={images}
              className={styles.RelatedPostCard_image_img}
            />
          )}
        </div>
        <div
          className={cx(
            styles.RelatedPostCard_content,
            styles[`RelatedPostCard_content_text_${align}`],
            {
              [styles.RelatedPostCard_content_overflow]: overflow,
            }
          )}
        >
          <h4 className={styles.RelatedPostCard_content_title}>{title}</h4>
          <div
            className={styles.RelatedPostCard_content_excerpt}
            dangerouslySetInnerHTML={{ __html: markdownToHtml(excerpt) }}
          ></div>
        </div>
      </Card>
    </Wrapper>
  );
};

export default RelatedPostCard;

import React from "react";
import { window } from "browser-monads";

const isBrowser = typeof window !== "undefined";

export default function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: isBrowser ? window.innerWidth : 1200,
    height: isBrowser ? window.innerHeight : 800,
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  React.useEffect(() => {
    window?.addEventListener("resize", changeWindowSize);

    return () => {
      window?.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return windowSize;
}

import MarkdownIt from "markdown-it";

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

export default function markdownToHtml(markdown: any) {
  return md.render(markdown);
}

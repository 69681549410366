import React from "react";
import { Modal } from "brass-ui-kit";
import { LayoutContext } from "context";
import { uniqueId } from "lodash";
import {
  DEFAULT_DOWNLOAD_ACTION_DATA,
  downloadActionTypeData,
} from "components/design-system/Modals/config";
import { CloseIcon } from "components/design-system/Modals/assets";
import { isAndroid, isIOS } from "react-device-detect";
import { AppDownloadType } from "types";
import { REDIRECT_URL } from "../config/generics";

import * as styles from "./AuthActionModal.module.scss";

const AuthActionModal: React.FC = () => {
  const { openAuthActionModal, authActionModalType, updateContext } =
    React.useContext(LayoutContext);

  const handleRedirect = (schemeUrl: string, storeUrl: string) => {
    window.open(schemeUrl);
    setTimeout(() => window.open(storeUrl, "_blank"), 300);
  };

  const handleAppRedirect = (type: AppDownloadType) => {
    const { scheme_url, store_url } = REDIRECT_URL[type];
    if (isAndroid) handleRedirect(scheme_url.google, store_url.google);
    if (isIOS) handleRedirect(scheme_url.ios, store_url.ios);
  };

  const handleStartAppRedirect = () => {
    const { ios, google } = REDIRECT_URL.starter.store_url;
    if (isAndroid) window.open(google, "_blank");
    if (isIOS) window.open(ios, "_blank");
  };

  const actionData = !!authActionModalType
    ? downloadActionTypeData(
        updateContext,
        () => handleAppRedirect(AppDownloadType.BusinessApp),
        handleStartAppRedirect
      )[authActionModalType]
    : DEFAULT_DOWNLOAD_ACTION_DATA;

  const handleClose = () =>
    updateContext({
      openAuthActionModal: false,
      authActionModalType: null,
    });

  return (
    <Modal
      isOpen={openAuthActionModal}
      className={styles.AuthActionModal}
      closeModal={handleClose}
    >
      <div className={styles.AuthActionModal_container}>
        <div className={styles.AuthActionModal_header}>
          <h3 className={styles.AuthActionModal_header_label}>
            {actionData.label}
          </h3>
          <CloseIcon onClick={handleClose} />
        </div>

        <ul className={styles.AuthActionModal_content}>
          {actionData.content.map((item) => (
            <li
              className={styles.AuthActionModal_content_item}
              key={uniqueId("download-action-item_")}
              onClick={item.action}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default AuthActionModal;

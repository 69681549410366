import React from "react";
import { Accordion, AccordionItem } from "brass-ui-kit";

import * as styles from "./FaqWidget.module.scss";
import { uniqueId } from "lodash";

interface FaqWidgetProps {
  content: { question: string; answer: string }[];
}

const FaqWidget: React.FC<FaqWidgetProps> = ({ content }) => {
  return (
    <section className={styles.FaqWidget}>
      <div className={styles.FaqWidget_container}>
        <header className={styles.FaqWidget_header}>
          <h2 className={styles.FaqWidget_header_title}>
            Some of the things you may want to know
          </h2>
          <p className={styles.FaqWidget_header_body}>
            We answered questions so you don’t have to ask them.
          </p>
        </header>
        <div className={styles.FaqWidget_list}>
          <Accordion>
            {content.map(({ question, answer }) => {
              return (
                <AccordionItem
                  body={answer}
                  header={question}
                  className={styles.FaqWidget_item}
                  activeClassName={styles.FaqWidget_item__active}
                  key={uniqueId()}
                />
              );
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FaqWidget;

export interface SEOProps {
  meta?: any;
  slug?: string;
  lang?: string;
  title?: string;
  image?: string;
  authorName?: string;
  description?: string;
  datePublished?: string;
  type?: "article" | "webpage" | "job";
  country?: string;
  city?: string;
  siteUrl?: string;
  logo?: string;
}

export type StructuredDataTypes = "article" | "webpage" | "job";

export type Image = {
  attributes: {
    ext: string;
    url: string;
    localFile: any;
  };
};

export type Category = {
  id: string;
  pagePath?: string;
  attributes: {
    slug: string;
    name: string;
  };
};

export type Author = {
  id: string;
  attributes: {
    slug: string;
    name: string;
    title: string;
    avatar: {
      data: Image;
    };
  };
};

export type Post = {
  id: string;
  attributes: {
    category: { data: Category };
    content: string;
    description: string;
    featuredPost: boolean;
    date: string;
    slug: string;
    title: string;
    featuredImage: {
      data: Image;
    };
    author?: {
      data: Author;
    };
  };
};

export type Guide = {
  id: string;
  attributes: {
    category: { data: Category };
    content: string;
    description: string;
    popular: boolean;
    date: string;
    slug: string;
    title: string;
    featuredImage: {
      data: Image;
    };
  };
};

export type Spotlight = {
  id: string;
  attributes: {
    title: string;
    description: string;
    url: string;
    poster: {
      data: Image;
    };
  };
};

export type WithData<T> = {
  data: T;
};

export type Embeds = Array<string>;

export type IObject = {
  [x: string]: any;
};

export interface Amount {
  raw: string;
  formatted: string;
  formatted_display: string;
}

export type File = {
  key?: string;
  url: string;
  content_type?: string;
};

export type TFunc<V, R = void> = (x: V) => R;

export enum AuthActionType {
  Login = "login",
  SignUp = "sign-up",
}

export enum AppDownloadType {
  BusinessApp = "business",
  StarterApp = "starter",
}

import React from "react";
import cx from "classnames";
import { InView } from "react-intersection-observer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./Support.module.scss";

interface SupportProps {
  reverse?: boolean;
  hasPadding?: boolean;
  theme?: "dark" | "light";
  items?: {
    title: string;
    description: string;
    subtitle: string;
    image?: any;
  }[];
}

const Support: React.FC<SupportProps> = ({
  theme,
  reverse,
  hasPadding,
  items,
}) => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  const images = items
    ? getImage(items[activeSlide].image.childImageSharp.gatsbyImageData)
    : null;

  return (
    <section
      className={cx(styles.Support, {
        [styles.Support_padding]: hasPadding === true,
      })}
    >
      <div className={cx({ [styles.Support_theme_dark]: theme === "dark" })}>
        <div
          className={cx(styles.Support_container, {
            [styles.Support_container_reverse]: reverse === true,
          })}
        >
          <div className={cx("grid-span-1-7", styles.Support_image)}>
            {items && images && (
              <GatsbyImage
                image={images}
                alt={items[activeSlide].title}
                as="span"
                loading="eager"
              />
            )}
          </div>
          <header className={cx("grid-span-8-6", styles.Support_header)}>
            {items?.map(({ title, description, subtitle }: any, i: number) => {
              return (
                <InView
                  key={title}
                  as="div"
                  threshold={0.7}
                  className={cx(styles.Support_header_section, {
                    [styles.Support_header_section_padding]: items.length > 1,
                  })}
                  onChange={(inView) => {
                    if (inView) {
                      setActiveSlide(i);
                    }
                  }}
                >
                  <span className={styles.Support_header_subtitle}>
                    {subtitle}
                  </span>
                  <h2
                    className={styles.Support_header_title}
                    dangerouslySetInnerHTML={{ __html: title! }}
                  />
                  <p
                    className={styles.Support_header_desc}
                    dangerouslySetInnerHTML={{ __html: description! }}
                  ></p>
                </InView>
              );
            })}
          </header>
        </div>
      </div>
    </section>
  );
};

Support.defaultProps = {
  theme: "light",
  reverse: false,
  hasPadding: true,
};

export default Support;

// extracted by mini-css-extract-plugin
export var Resources = "Resources-module--Resources--IXQVW";
export var Resources_container = "Resources-module--Resources_container--rBXb-";
export var Resources_header = "Resources-module--Resources_header--Yc7TX";
export var Resources_header_center = "Resources-module--Resources_header_center--N3EsR";
export var Resources_header_desc = "Resources-module--Resources_header_desc--ZhpC8";
export var Resources_header_left = "Resources-module--Resources_header_left--ISumx";
export var Resources_header_right = "Resources-module--Resources_header_right--poBwX";
export var Resources_header_title = "Resources-module--Resources_header_title--95ic7";
export var Resources_item = "Resources-module--Resources_item--F3uh8";
export var Resources_item_content = "Resources-module--Resources_item_content--EIddI";
export var Resources_item_content_excerpt = "Resources-module--Resources_item_content_excerpt---GK-I";
export var Resources_item_content_overflow = "Resources-module--Resources_item_content_overflow--D7PDw";
export var Resources_item_content_text_center = "Resources-module--Resources_item_content_text_center--ZUmcY";
export var Resources_item_content_text_left = "Resources-module--Resources_item_content_text_left--QMTLX";
export var Resources_item_content_text_right = "Resources-module--Resources_item_content_text_right--OLh3r";
export var Resources_item_content_title = "Resources-module--Resources_item_content_title--i-xLa";
export var Resources_item_image = "Resources-module--Resources_item_image--dw7xl";
export var Resources_item_image_img = "Resources-module--Resources_item_image_img--ltqrG";
export var Resources_main = "Resources-module--Resources_main--tWLEy";
export var container = "Resources-module--container--srMAP";
export var gatsbyImageWrapperConstrained = "Resources-module--gatsby-image-wrapper-constrained--Mo0k3";
export var gridSpan1010 = "Resources-module--grid-span-10-10--h6trM";
export var gridSpan1011 = "Resources-module--grid-span-10-11--VNmzU";
export var gridSpan102 = "Resources-module--grid-span-10-2--oBiKO";
export var gridSpan103 = "Resources-module--grid-span-10-3--+uqP+";
export var gridSpan104 = "Resources-module--grid-span-10-4--ffUKd";
export var gridSpan105 = "Resources-module--grid-span-10-5--uNirE";
export var gridSpan106 = "Resources-module--grid-span-10-6--FFv3+";
export var gridSpan107 = "Resources-module--grid-span-10-7--fT0R9";
export var gridSpan108 = "Resources-module--grid-span-10-8--TdAQ-";
export var gridSpan109 = "Resources-module--grid-span-10-9--hN-gh";
export var gridSpan110 = "Resources-module--grid-span-1-10--KAinb";
export var gridSpan111 = "Resources-module--grid-span-1-11--3JSX9";
export var gridSpan1110 = "Resources-module--grid-span-11-10--cNxsu";
export var gridSpan1111 = "Resources-module--grid-span-11-11--7HY8K";
export var gridSpan112 = "Resources-module--grid-span-11-2--xbmti";
export var gridSpan113 = "Resources-module--grid-span-11-3--IRG96";
export var gridSpan114 = "Resources-module--grid-span-11-4--bz4Sl";
export var gridSpan115 = "Resources-module--grid-span-11-5--wcLHP";
export var gridSpan116 = "Resources-module--grid-span-11-6--stHx1";
export var gridSpan117 = "Resources-module--grid-span-11-7--q-gW0";
export var gridSpan118 = "Resources-module--grid-span-11-8--DESI1";
export var gridSpan119 = "Resources-module--grid-span-11-9--D-+sH";
export var gridSpan12 = "Resources-module--grid-span-1-2--u68Jq";
export var gridSpan1210 = "Resources-module--grid-span-12-10--Gp7qN";
export var gridSpan1211 = "Resources-module--grid-span-12-11--J6xqO";
export var gridSpan122 = "Resources-module--grid-span-12-2--JgTFL";
export var gridSpan123 = "Resources-module--grid-span-12-3--QS3r+";
export var gridSpan124 = "Resources-module--grid-span-12-4--2ibHQ";
export var gridSpan125 = "Resources-module--grid-span-12-5--ELdjV";
export var gridSpan126 = "Resources-module--grid-span-12-6--xLryd";
export var gridSpan127 = "Resources-module--grid-span-12-7--Kf678";
export var gridSpan128 = "Resources-module--grid-span-12-8--T6luK";
export var gridSpan129 = "Resources-module--grid-span-12-9--itw5d";
export var gridSpan13 = "Resources-module--grid-span-1-3--0gF0N";
export var gridSpan14 = "Resources-module--grid-span-1-4--9zAG8";
export var gridSpan15 = "Resources-module--grid-span-1-5--oGXVn";
export var gridSpan16 = "Resources-module--grid-span-1-6--+A+h8";
export var gridSpan17 = "Resources-module--grid-span-1-7--bxCpg";
export var gridSpan18 = "Resources-module--grid-span-1-8--XWySC";
export var gridSpan19 = "Resources-module--grid-span-1-9--Myn6G";
export var gridSpan210 = "Resources-module--grid-span-2-10--INZXI";
export var gridSpan211 = "Resources-module--grid-span-2-11--yu2YD";
export var gridSpan22 = "Resources-module--grid-span-2-2--D-mEW";
export var gridSpan23 = "Resources-module--grid-span-2-3--4Zpfa";
export var gridSpan24 = "Resources-module--grid-span-2-4--DcCl7";
export var gridSpan25 = "Resources-module--grid-span-2-5--DOwCh";
export var gridSpan26 = "Resources-module--grid-span-2-6--uYQxV";
export var gridSpan27 = "Resources-module--grid-span-2-7--lyIlm";
export var gridSpan28 = "Resources-module--grid-span-2-8--L5KLn";
export var gridSpan29 = "Resources-module--grid-span-2-9--z-f5p";
export var gridSpan310 = "Resources-module--grid-span-3-10--yQM9P";
export var gridSpan311 = "Resources-module--grid-span-3-11--T4Sr6";
export var gridSpan32 = "Resources-module--grid-span-3-2--GQb9d";
export var gridSpan33 = "Resources-module--grid-span-3-3--LOYQy";
export var gridSpan34 = "Resources-module--grid-span-3-4--hv-vr";
export var gridSpan35 = "Resources-module--grid-span-3-5--DXIBt";
export var gridSpan36 = "Resources-module--grid-span-3-6--g-InH";
export var gridSpan37 = "Resources-module--grid-span-3-7--xA2-v";
export var gridSpan38 = "Resources-module--grid-span-3-8--j7mw+";
export var gridSpan39 = "Resources-module--grid-span-3-9--FMBlc";
export var gridSpan410 = "Resources-module--grid-span-4-10--Tszic";
export var gridSpan411 = "Resources-module--grid-span-4-11--hdWY9";
export var gridSpan42 = "Resources-module--grid-span-4-2--WeCKE";
export var gridSpan43 = "Resources-module--grid-span-4-3--hzXU+";
export var gridSpan44 = "Resources-module--grid-span-4-4--l+E9h";
export var gridSpan45 = "Resources-module--grid-span-4-5--mix2x";
export var gridSpan46 = "Resources-module--grid-span-4-6--RXMj3";
export var gridSpan47 = "Resources-module--grid-span-4-7--g-6Ti";
export var gridSpan48 = "Resources-module--grid-span-4-8--5hDeT";
export var gridSpan49 = "Resources-module--grid-span-4-9--Kw6MH";
export var gridSpan510 = "Resources-module--grid-span-5-10--jP-TS";
export var gridSpan511 = "Resources-module--grid-span-5-11--XZVMb";
export var gridSpan52 = "Resources-module--grid-span-5-2--E77wJ";
export var gridSpan53 = "Resources-module--grid-span-5-3--ErI41";
export var gridSpan54 = "Resources-module--grid-span-5-4--XYSPa";
export var gridSpan55 = "Resources-module--grid-span-5-5--YFtnA";
export var gridSpan56 = "Resources-module--grid-span-5-6--BgRK1";
export var gridSpan57 = "Resources-module--grid-span-5-7--eSrbX";
export var gridSpan58 = "Resources-module--grid-span-5-8--QK9U1";
export var gridSpan59 = "Resources-module--grid-span-5-9--Orkrm";
export var gridSpan610 = "Resources-module--grid-span-6-10--DyJ4G";
export var gridSpan611 = "Resources-module--grid-span-6-11--EQYpJ";
export var gridSpan62 = "Resources-module--grid-span-6-2--sANGF";
export var gridSpan63 = "Resources-module--grid-span-6-3--ETKCS";
export var gridSpan64 = "Resources-module--grid-span-6-4--P3Fp5";
export var gridSpan65 = "Resources-module--grid-span-6-5--i3JCJ";
export var gridSpan66 = "Resources-module--grid-span-6-6--n2ChA";
export var gridSpan67 = "Resources-module--grid-span-6-7--YdbfN";
export var gridSpan68 = "Resources-module--grid-span-6-8--g0cLa";
export var gridSpan69 = "Resources-module--grid-span-6-9--Yi2yj";
export var gridSpan710 = "Resources-module--grid-span-7-10--0yzec";
export var gridSpan711 = "Resources-module--grid-span-7-11--b2djo";
export var gridSpan72 = "Resources-module--grid-span-7-2--NgNaD";
export var gridSpan73 = "Resources-module--grid-span-7-3--pNAay";
export var gridSpan74 = "Resources-module--grid-span-7-4--g-5SJ";
export var gridSpan75 = "Resources-module--grid-span-7-5--zw04I";
export var gridSpan76 = "Resources-module--grid-span-7-6--KUzRf";
export var gridSpan77 = "Resources-module--grid-span-7-7--l3GSG";
export var gridSpan78 = "Resources-module--grid-span-7-8--HaE9R";
export var gridSpan79 = "Resources-module--grid-span-7-9--ZlJpN";
export var gridSpan810 = "Resources-module--grid-span-8-10--WJan2";
export var gridSpan811 = "Resources-module--grid-span-8-11--vRz9j";
export var gridSpan82 = "Resources-module--grid-span-8-2--4UmYk";
export var gridSpan83 = "Resources-module--grid-span-8-3--x8I67";
export var gridSpan84 = "Resources-module--grid-span-8-4--x+8ps";
export var gridSpan85 = "Resources-module--grid-span-8-5--R8Pwx";
export var gridSpan86 = "Resources-module--grid-span-8-6--8tH7C";
export var gridSpan87 = "Resources-module--grid-span-8-7--Tjzkg";
export var gridSpan88 = "Resources-module--grid-span-8-8--aSVH+";
export var gridSpan89 = "Resources-module--grid-span-8-9--ysjMX";
export var gridSpan910 = "Resources-module--grid-span-9-10--JbeCM";
export var gridSpan911 = "Resources-module--grid-span-9-11--8+0+V";
export var gridSpan92 = "Resources-module--grid-span-9-2--TE2uo";
export var gridSpan93 = "Resources-module--grid-span-9-3--rhWkN";
export var gridSpan94 = "Resources-module--grid-span-9-4--wMjXu";
export var gridSpan95 = "Resources-module--grid-span-9-5--KHxO1";
export var gridSpan96 = "Resources-module--grid-span-9-6--RJCtD";
export var gridSpan97 = "Resources-module--grid-span-9-7--LIJ5G";
export var gridSpan98 = "Resources-module--grid-span-9-8--1gqME";
export var gridSpan99 = "Resources-module--grid-span-9-9--wb6RF";
export var textBreak = "Resources-module--text-break--zOqGf";
import { isEmpty, isPlainObject, transform } from "lodash";
import { File, TFunc } from "types";
import fileDownload from "js-file-download";
import axios, { AxiosResponse } from "axios";
import { window } from "browser-monads";

export const extractCategories = (data: any, pathPrefix: string) => {
  return data
    .reduce((categories: any, entity: any) => {
      if (
        categories.findIndex(
          (category: any) => category.id === entity.attributes.category.data.id
        ) < 0
      )
        categories.push({
          ...entity.attributes.category.data,
          pagePath: `${pathPrefix}/${entity.attributes.category.data.attributes.slug}`,
        });
      return categories;
    }, [])
    .sort((a: any, b: any) => a.id - b.id);
};

export function clean<T = any>(object: T) {
  return transform<any, T>(object, (result, value, key) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
      value = clean(value);
    }

    // Exclude empty objects.
    if (isPlainObject(value) && isEmpty(value)) {
      return;
    }

    // Exclude empty arrays.
    if (Array.isArray(value) && !value.length) {
      return;
    }

    // Exclude empty strings.
    if (value === "") {
      return;
    }

    // Exclude NaN values.
    if (Number.isNaN(value)) {
      return;
    }

    // Exclude null values.
    if (value === null) {
      return;
    }

    // Exclude undefined values.
    if (value === undefined) {
      return;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
      return result.push(value);
    }

    result[key] = value;
  });
}

export const downloadFile: TFunc<File> = async ({
  url,
  key = "",
  content_type,
}) => {
  const { data } = await axios.get(url, { responseType: "blob" });
  const filename = [...key.split("/")].pop() || "";
  fileDownload(data as any, filename, content_type);
};

export const formatNumWithCommas = (num: string) => {
  let str = parseInt(num).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
  str = str.replace(/\./, ".");
  str = str.replace(/,/g, ",");
  return str;
};

export const sendEmail = (message: {
  email: string;
  body: string;
  subject: string;
}) => {
  const email = message.email;
  const subject = message.subject;
  const emailBody = message.body;
  const body = document.querySelector("body");
  const link = `mailto:${email}?subject=${subject}&body=${emailBody}`;
  const trigger = document.createElement("a");
  trigger.setAttribute("href", link);
  trigger.setAttribute("target", "_blank");
  trigger.setAttribute("rel", "noreferrer");
  trigger.setAttribute("style", "visibility:hidden");
  body?.appendChild(trigger);
  trigger.click();
  body?.removeChild(trigger);
};

export const numberFormatter = (options: Partial<Intl.NumberFormatOptions>) =>
  new Intl.NumberFormat("en-US", options);

export const koboToNaira = (
  koboValue: number,
  options: Partial<Intl.NumberFormatOptions> = { minimumFractionDigits: 2 }
) => numberFormatter(options).format(koboValue / 100);

export const detectMobileDevice = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return window.navigator.userAgent.match(toMatchItem);
  });
};

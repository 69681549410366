// extracted by mini-css-extract-plugin
export var Card_container = "Card-module--Card_container--Bl9Gd";
export var Card_content = "Card-module--Card_content--jSdos";
export var Card_description = "Card-module--Card_description--g3XpE";
export var Card_image = "Card-module--Card_image--zswBn";
export var Card_meta = "Card-module--Card_meta--Qs9E5";
export var Card_meta_date = "Card-module--Card_meta_date--S68Cw";
export var Card_meta_left = "Card-module--Card_meta_left--vp-P8";
export var Card_meta_link = "Card-module--Card_meta_link--0XFA2";
export var Card_meta_team = "Card-module--Card_meta_team--k+Sp9";
export var Card_title = "Card-module--Card_title--vjZbd";
export var container = "Card-module--container--NTGUC";
export var gatsbyImageWrapperConstrained = "Card-module--gatsby-image-wrapper-constrained--C0brR";
export var gridSpan1010 = "Card-module--grid-span-10-10--0SLYD";
export var gridSpan1011 = "Card-module--grid-span-10-11--Z9T3P";
export var gridSpan102 = "Card-module--grid-span-10-2--bKcjd";
export var gridSpan103 = "Card-module--grid-span-10-3--njj-1";
export var gridSpan104 = "Card-module--grid-span-10-4--9WCJz";
export var gridSpan105 = "Card-module--grid-span-10-5---oCGP";
export var gridSpan106 = "Card-module--grid-span-10-6--2MKrQ";
export var gridSpan107 = "Card-module--grid-span-10-7--BcyW7";
export var gridSpan108 = "Card-module--grid-span-10-8--sBngU";
export var gridSpan109 = "Card-module--grid-span-10-9--uWEi-";
export var gridSpan110 = "Card-module--grid-span-1-10--IntJj";
export var gridSpan111 = "Card-module--grid-span-1-11--v-Xlr";
export var gridSpan1110 = "Card-module--grid-span-11-10--jSBnc";
export var gridSpan1111 = "Card-module--grid-span-11-11--Vk+on";
export var gridSpan112 = "Card-module--grid-span-11-2--XPqAY";
export var gridSpan113 = "Card-module--grid-span-11-3--qDcHo";
export var gridSpan114 = "Card-module--grid-span-11-4--ZpGOn";
export var gridSpan115 = "Card-module--grid-span-11-5--hXpv1";
export var gridSpan116 = "Card-module--grid-span-11-6--yyBW7";
export var gridSpan117 = "Card-module--grid-span-11-7--Fd1+9";
export var gridSpan118 = "Card-module--grid-span-11-8--QsPAz";
export var gridSpan119 = "Card-module--grid-span-11-9--+YUAg";
export var gridSpan12 = "Card-module--grid-span-1-2--S3a1m";
export var gridSpan1210 = "Card-module--grid-span-12-10--Y3wJO";
export var gridSpan1211 = "Card-module--grid-span-12-11--ltXfw";
export var gridSpan122 = "Card-module--grid-span-12-2--Ma3RF";
export var gridSpan123 = "Card-module--grid-span-12-3--0hTW+";
export var gridSpan124 = "Card-module--grid-span-12-4--ONeRf";
export var gridSpan125 = "Card-module--grid-span-12-5--DwC9d";
export var gridSpan126 = "Card-module--grid-span-12-6--C2jlE";
export var gridSpan127 = "Card-module--grid-span-12-7--b+P95";
export var gridSpan128 = "Card-module--grid-span-12-8--aLUKQ";
export var gridSpan129 = "Card-module--grid-span-12-9--gCQk4";
export var gridSpan13 = "Card-module--grid-span-1-3--3hF6T";
export var gridSpan14 = "Card-module--grid-span-1-4--t+jY4";
export var gridSpan15 = "Card-module--grid-span-1-5--m7CL4";
export var gridSpan16 = "Card-module--grid-span-1-6--AyUIC";
export var gridSpan17 = "Card-module--grid-span-1-7--T8Ng-";
export var gridSpan18 = "Card-module--grid-span-1-8--lSwxE";
export var gridSpan19 = "Card-module--grid-span-1-9--7E5Eb";
export var gridSpan210 = "Card-module--grid-span-2-10--ZBM91";
export var gridSpan211 = "Card-module--grid-span-2-11--4boV6";
export var gridSpan22 = "Card-module--grid-span-2-2--LrF3z";
export var gridSpan23 = "Card-module--grid-span-2-3--1YKxA";
export var gridSpan24 = "Card-module--grid-span-2-4--xgyeE";
export var gridSpan25 = "Card-module--grid-span-2-5--t9Mgj";
export var gridSpan26 = "Card-module--grid-span-2-6--Q3eqY";
export var gridSpan27 = "Card-module--grid-span-2-7--hJ2j+";
export var gridSpan28 = "Card-module--grid-span-2-8--nr0MI";
export var gridSpan29 = "Card-module--grid-span-2-9--4TVll";
export var gridSpan310 = "Card-module--grid-span-3-10--xeEVD";
export var gridSpan311 = "Card-module--grid-span-3-11--+RQ8+";
export var gridSpan32 = "Card-module--grid-span-3-2--6T3-w";
export var gridSpan33 = "Card-module--grid-span-3-3--IdQ4r";
export var gridSpan34 = "Card-module--grid-span-3-4--IMhrS";
export var gridSpan35 = "Card-module--grid-span-3-5--bIi4m";
export var gridSpan36 = "Card-module--grid-span-3-6--ldnP-";
export var gridSpan37 = "Card-module--grid-span-3-7--J8IsV";
export var gridSpan38 = "Card-module--grid-span-3-8--2Me+d";
export var gridSpan39 = "Card-module--grid-span-3-9--2ccjs";
export var gridSpan410 = "Card-module--grid-span-4-10--rsCYI";
export var gridSpan411 = "Card-module--grid-span-4-11--VRquR";
export var gridSpan42 = "Card-module--grid-span-4-2--IG13G";
export var gridSpan43 = "Card-module--grid-span-4-3--xHk4-";
export var gridSpan44 = "Card-module--grid-span-4-4--Yqj+q";
export var gridSpan45 = "Card-module--grid-span-4-5--OcRei";
export var gridSpan46 = "Card-module--grid-span-4-6--jMS+6";
export var gridSpan47 = "Card-module--grid-span-4-7--DWKN+";
export var gridSpan48 = "Card-module--grid-span-4-8--vgWFj";
export var gridSpan49 = "Card-module--grid-span-4-9--PkNJ2";
export var gridSpan510 = "Card-module--grid-span-5-10--COkC6";
export var gridSpan511 = "Card-module--grid-span-5-11--mzYwQ";
export var gridSpan52 = "Card-module--grid-span-5-2--vpslp";
export var gridSpan53 = "Card-module--grid-span-5-3--haO6M";
export var gridSpan54 = "Card-module--grid-span-5-4--PL2fG";
export var gridSpan55 = "Card-module--grid-span-5-5--NPmsp";
export var gridSpan56 = "Card-module--grid-span-5-6--LoxK0";
export var gridSpan57 = "Card-module--grid-span-5-7--IY4km";
export var gridSpan58 = "Card-module--grid-span-5-8--bbZGT";
export var gridSpan59 = "Card-module--grid-span-5-9--czkOY";
export var gridSpan610 = "Card-module--grid-span-6-10--3g9UL";
export var gridSpan611 = "Card-module--grid-span-6-11--AuY7H";
export var gridSpan62 = "Card-module--grid-span-6-2--G7Mz+";
export var gridSpan63 = "Card-module--grid-span-6-3--1-6kg";
export var gridSpan64 = "Card-module--grid-span-6-4--jhGFB";
export var gridSpan65 = "Card-module--grid-span-6-5--yQDM-";
export var gridSpan66 = "Card-module--grid-span-6-6--ukg++";
export var gridSpan67 = "Card-module--grid-span-6-7--FTFGB";
export var gridSpan68 = "Card-module--grid-span-6-8--qNzGP";
export var gridSpan69 = "Card-module--grid-span-6-9--ZTmJy";
export var gridSpan710 = "Card-module--grid-span-7-10--wZeVC";
export var gridSpan711 = "Card-module--grid-span-7-11--OSwad";
export var gridSpan72 = "Card-module--grid-span-7-2--yvY0y";
export var gridSpan73 = "Card-module--grid-span-7-3--mOilu";
export var gridSpan74 = "Card-module--grid-span-7-4--2KUPv";
export var gridSpan75 = "Card-module--grid-span-7-5--krLIt";
export var gridSpan76 = "Card-module--grid-span-7-6--x2a8J";
export var gridSpan77 = "Card-module--grid-span-7-7--pfHu+";
export var gridSpan78 = "Card-module--grid-span-7-8--M8vII";
export var gridSpan79 = "Card-module--grid-span-7-9--vqivX";
export var gridSpan810 = "Card-module--grid-span-8-10--FfUEa";
export var gridSpan811 = "Card-module--grid-span-8-11--DBacv";
export var gridSpan82 = "Card-module--grid-span-8-2--6Ipbw";
export var gridSpan83 = "Card-module--grid-span-8-3--Y8OFz";
export var gridSpan84 = "Card-module--grid-span-8-4--Jwxkf";
export var gridSpan85 = "Card-module--grid-span-8-5--v0PrO";
export var gridSpan86 = "Card-module--grid-span-8-6--CVee+";
export var gridSpan87 = "Card-module--grid-span-8-7--MfyqF";
export var gridSpan88 = "Card-module--grid-span-8-8--YnlBy";
export var gridSpan89 = "Card-module--grid-span-8-9--GiZld";
export var gridSpan910 = "Card-module--grid-span-9-10--woAIw";
export var gridSpan911 = "Card-module--grid-span-9-11--3vMLv";
export var gridSpan92 = "Card-module--grid-span-9-2--AhzGU";
export var gridSpan93 = "Card-module--grid-span-9-3--BHpml";
export var gridSpan94 = "Card-module--grid-span-9-4--7M093";
export var gridSpan95 = "Card-module--grid-span-9-5--0m5mD";
export var gridSpan96 = "Card-module--grid-span-9-6--huGme";
export var gridSpan97 = "Card-module--grid-span-9-7--TxebH";
export var gridSpan98 = "Card-module--grid-span-9-8--QL+3t";
export var gridSpan99 = "Card-module--grid-span-9-9---OoW+";
export var textBreak = "Card-module--text-break--MHY0W";